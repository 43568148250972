<template>
    <div class="section pp-scrollable bdz-section-cityhall" data-menuanchor="shopnwin" :style="cssImgBgCityHall">
       <div class="slide-container">
         <div class="slide-bg">
           <div class="slide-photo circle-green slide-awards-circle1 transformLeft"></div>
           <div class="slide-photo circle-brown slide-awards-circle2 transformRight"></div>
         </div>
         <div class="bdz-section-main-slider-hover"></div>

         <div class="container">
           <div class="row align-items-center mb-3 animate-element delay5 fadeInDown">
               <h2 class="slide-title">PIM City Hall</h2>
           </div>
         

                  <div :key="cityhall.length" class="a-carousel-cityhall owl-carousel owl-theme bdz-cityhall-content animate-element delay5 fadeInUp">
                    <div class="bdz-carousel-cityhall-item" v-for="item in cityhall" :key="item.news_id">
                        <div  class="bdz-img-city-hall">
                            <span v-for="images in item.news_image" :key="images.img_id">
                              <img :src="this.$assetImgSmall+'news/' + images.img_name" alt="">
                            </span>  
                          </div>

                            <div class=" testimonials-item bdz-cityhall-description">
                                <div class="testimonials-item-text slide-title-info">{{ item.news_title }}</div>
                                <!-- <div class="testimonials-item-author">{{ item.news_date }}</div> -->
                                <br>
                                <div class="testimonials-desc" >
                                  {{ item.news_excert }}
                                </div>

                                <!-- <router-link 
                                  :to="`/cityhall/${item.news_permalink}`"
                                  class="bdz-cityhall-read"
                                > <span class="bdz-cityhall-read-button">Read more</span></router-link> -->
                                <a class="bdz-cityhall-read" :href="'/cityhall/' + item.news_permalink">
                                    <span class="bdz-cityhall-read-button">Read more</span>
                                </a>
                                
                            </div>
                    </div>
                    <!-- <div class="video-link-descr slide-descr">*Sanfasio Agency’s Stories</div> -->
                  </div>
                  <!-- <div class="col-md-1 col-xl-2 d-none d-lg-block"></div> -->
                


              <div class="clearfix"></div> 
              <br>
              <div class="col-sm-12 col-12 mt-4 bdz-more-article-wrapper text-center">
                <div class="bdz-more-article-container">
                  <a class="btn btn-success btn-sm bdz-more-article-btn" :href="'/list/cityhall'">See More City Hall</a>
                </div>
              </div>

         </div>
       </div>
     </div>
</template>

<script>
export default{
   props: {
    //  descSNW : String,
     imgBgCityHall : String,

   },
   computed: {
      cssImgBgCityHall() {
        return {
          "background-image": "url('"+ this.$assetImgSmall+'profile/' + this.imgBgCityHall +"')",
          "background-position": "center",
        };
      }
    },
   name:'MainCityHall',
   methods: {
     async getCityhall() {
       await this.$axios
         .get(this.$api + '/cityhall/6/1')
         .then((response) => {
           console.log(response.data.data);
           this.cityhall = response.data.data;
       });
     },  
   },
   data() {
     return {
        cityhall : []
         }
   },
   beforeMount(){
      this.getCityhall();
    }
}
</script>