<template>
      <header id="header" class="header header-fixed">
    <div class="header-bg"></div>
    <div class="container-fluid clearfix">
      <div class="brand">
        <RouterLink to="/#Home">
          <div class="brand-name font-custom">
            <img src="../assets/img/logo.png" class="bdz-brand-logo-red" width="100" alt="" />
            <img src="../assets/img/logo4.png" class="bdz-brand-logo-white" width="100" alt="" />
          </div>
        </RouterLink>
      </div>

      <button class="nav-toggle-btn a-nav-toggle">
        <span class="nav-toggle">
          <span class="stick stick-1 black"></span>
          <span class="stick stick-2 black"></span>
        </span>
      </button>

      <div class="header-content d-none d-md-block">
        <div class="row">
          <div class="col-md-1">
            <div class="header-tagline"></div>
          </div>
          <div class="col-md-3  ">
            <div class="header-contacts">
              <div class="header-contact-item">
                <input
                  type="text"
                  class="bdz-input-search"
                  placeholder="Let's find anything.."
                  v-model="searchText" 
                  v-on:keyup="submitSearch" 
                />
                <i class="lni lni-search bdz-input-search-button" ></i>
                <!-- <input
                  type="button"
                  class="btn bdz-input-search-button"
                  value="Search"
                /> -->
              </div>
              <!-- <div class="header-contact-divider">/</div>     
              <div class="header-contact-item">
                <a href="mailto:hayley.design@gmail.com" class="mail-link"
                  >hayley.design@gmail.com</a
                >
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="hide-menu a-nav-toggle"></div>

    <div class="menu">
      <div class="menu-lang">
        <a href="#" class="menu-lang-item active"
          >Welcome to Pondok Indah Mall</a
        >
      </div>

      <nav class="menu-main" id="accordion">
        <ul id="menuMain">
          <li data-menuanchor="landing" class="active">
            <a href="/#Landing">Intro</a>
          </li>
          <li data-menuanchor="home" class="active">
            <a href="/#Home">Home</a>
          </li>
          <li data-menuanchor="directory">
            <a href="/#Directory">Directory</a>
          </li>
          <li data-menuanchor="cityhall">
            <a href="/#Cityhall">PIM City Hall</a>
          </li>
          <li data-menuanchor="facilities">
            <a href="/#Facilities">Facilities</a>
          </li>
          <li data-menuanchor="whatsnew">
            <a href="/#Whatsnew">What's New</a>
          </li>
          <li data-menuanchor="shopnwin">
            <a href="/#Shopnwin">Shop n Win</a>
          </li>
          <li data-menuanchor="waterpark">
            <a href="/#Waterpark">Waterpark</a>
          </li>
          <li data-menuanchor="events">
            <a href="/#Events">Events</a>
          </li>
          <li data-menuanchor="Career"><a href="/career">Career</a></li>
          <li data-menuanchor="Contact"><a href="/#Contact">Contact</a></li>
        </ul>
      </nav>

      <div class="menu-footer">
        <ul class="social social-rounded">
          <li>
            <a target="_blank" :href="'https://twitter.com/'+this.x"><i class="lni lni-twitter-filled"></i></a>
          </li>
          <li>
            <a target="_blank" :href="'https://facebook.com/'+this.facebook"><i class="lni lni-facebook-original"></i></a>
          </li>
          <li>
            <a target="_blank" :href="'https://instagram.com/'+ this.instagram"><i class="lni lni-instagram-original"></i></a>
          </li>
        </ul>
        <div class="menu-copyright">
          &copy; Pondok Indah Mall 2024<br />
          All Rights Reserved
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'MainHeader',
  props: {
      x: String,
      facebook: String,
      instagram : String,
    },
    data() {
      return {
            searchText : '',
          }
    },
  methods : {
    submitSearch: function(e) {
        if (e.keyCode === 13)
          // alert(this.searchText);
          this.$router.replace({ name: 'SearchView', params: { key: this.searchText } });
      },
  }
}
</script>
