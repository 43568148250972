<template>
       <div class="section pp-scrollable" data-menuanchor="Waterpark">
        
        <div class="bdz-wp-wrapper">
          <div class="bdz-wp-container">
            <div class="bdz-wp-menu-wrapper  animate-element delay1 fadeInDown">
              <div class="bdz-wp-menu-container">
                <img src="../assets/img/logo-wp.png" alt="" class="bdz-wp-menu-logo">
                <div class="clearfix"></div>
                <ul class="bdz-wp-menu-list">

                   <!-- <li><RouterLink to="/#DetailWaterpark">Rides</RouterLink></li> -->
                  <!--  <li><RouterLink to="/?page=info#DetailWaterpark">Info</RouterLink></li>
                  <li><RouterLink to="/?page=reservation#DetailWaterpark">Reservation</RouterLink></li> -->
                  <li><a target="_blank" :href="waterpark.wp_ticket_url"><img src="../assets/img/btn-buy-ticket-pim.png" alt=""></a></li>
                  <!-- <li><RouterLink to="/?page=news#DetailWaterpark">News</RouterLink></li> -->
                </ul>
              </div>
            </div>
            <div class="bdz-wp-bg-anim  animate-element delay7 fadeInUp">
              <div class="bdz-wp-bg-anim-slider">
                <img src="../assets/img/waterpark-vector.png" alt="" class="">
              </div>
              <div class="bdz-wp-bg-anim-hand-right">
                <img src="../assets/img/hand.png" alt="" class="bdz-wp-bg-anim-hand-item">
              </div>
              <div class="bdz-wp-bg-anim-hand-left">
                <img src="../assets/img/hand.png" alt="" class="bdz-wp-bg-anim-hand-item">
              </div>
              <div class="bdz-wp-bg-anim-wave-b">
                <img src="../assets/img/wave.png" alt="" class="bdz-wp-bg-anim-wave-item">
                <img src="../assets/img/wave.png" alt="" class="bdz-wp-bg-anim-wave-item">
                <img src="../assets/img/wave.png" alt="" class="bdz-wp-bg-anim-wave-item">
                <img src="../assets/img/wave.png" alt="" class="bdz-wp-bg-anim-wave-item">
                <img src="../assets/img/wave.png" alt="" class="bdz-wp-bg-anim-wave-item">
                <img src="../assets/img/wave.png" alt="" class="bdz-wp-bg-anim-wave-item">
                <img src="../assets/img/wave.png" alt="" class="bdz-wp-bg-anim-wave-item">
                <img src="../assets/img/wave.png" alt="" class="bdz-wp-bg-anim-wave-item">
                <img src="../assets/img/wave.png" alt="" class="bdz-wp-bg-anim-wave-item">
                <img src="../assets/img/wave.png" alt="" class="bdz-wp-bg-anim-wave-item">
                <img src="../assets/img/wave.png" alt="" class="bdz-wp-bg-anim-wave-item">
              </div>
              <div class="bdz-wp-bg-anim-head">
                <img src="../assets/img/viking.png" alt="" class="bdz-wp-bg-anim-wave-item">

                <div class="bdz-wp-bg-anim-wave-f">
                  <img src="../assets/img/wave-f.png" alt="" class="bdz-wp-bg-anim-wave-item">
                </div>
                
              </div>
              <div class="clearfix"></div>
             
            </div>
            
         
            <div class="clearfix"></div>
            <div class="bdz-wp-content-wrapper  animate-element delay7 fadeInUp">
              <marquee behavior="" direction="" class="bdz-wp-running-text">
                  {{ waterpark.wp_desc_4 }}
                </marquee>
              <div class="bdz-wp-content-container">
               
                <div class="bdz-wp-content-title">ABOUT US</div>
                <div class="bdz-wp-content-desc" v-html="waterpark.wp_desc_1">
                  
                </div>
                <div class="clearfix"></div> 
                <div class="bdz-wp-content-btn-rides ">
                  <div class="bdz-rides-detail-back text-center pb-5">
                    <a href="/#Rides" class="bdz-rides-detail-back-button">Check Our Rides</a>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

</template>


<script>
export default{
    props: {
      // descSNW : String,
    },
    name:'MainWaterpark',
    methods: {
      async getWP() {
        await this.$axios
          .get(this.$api + '/waterpark')
          .then((response) => {
            console.log(response.data.data);
            this.waterpark = response.data.data;
        });
      },  
    },
    data() {
      return {
            waterpark : []
          }
    },
    beforeMount(){
      this.getWP();
    }
}
</script>