<template>
    <MainHeader></MainHeader>
    <div id="content" class="content">
      <div class="homepage-personal a-pagepiling">
        <DetailRides></DetailRides>
      </div>
    </div>
    <MainFooter></MainFooter>
  </template>
  
  <script>
  // @ is an alias to /src
  import MainHeader from '@/components/MainHeader.vue'
  import MainFooter from '@/components/MainFooter.vue'
  import DetailRides from '@/components/DetailRides.vue'
  // import $ from 'jquery'
  
  export default {
    name: 'RidesDetailView',
    components: {
      MainHeader,
      DetailRides,
      MainFooter    ,
    },
    mounted() {
      let js = document.createElement("script");
      js.setAttribute("src", "/js/script.js");
      document.head.appendChild(js);
      // $.fn.pagepiling.destroy('all');
    },
  }
  </script>
  