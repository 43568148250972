<template>
    
 
  <div class="section pp-scrollable bdz-section-directory-detail  slide-dark slide-personal-experience" :style="cssImgBgShopnwin">
          <div class="slide-container">
            
        <div class="slide-bg"></div>
        <div class="bdz-section-hover-dark"></div>

            <div class="container">
              <div class="row align-items-center mb-4 animate-element delay5 fadeInDown">
                <div class="col-12">
                  <div class="bdz-directory-detail-back-button">
                      <a class="bdz-directory-detail-back-link" href="/#Shopnwin">Back to Home</a>
                  </div>
                  <h2 class="slide-title mb-0">Shop n Win PIM</h2>
                 
                </div>
              
              </div>
              <div class="animate-element delay5 fadeInUp">
                <div class="">
                  <div class="experience-list">
                    <div class="experience-item">
                      <div class="row bdz-career-item">
                        <div class="col-md-4">
                          <span v-for="images in article.shopnwin_image" :key="images.img_id">
                            <img class="bdz-facilities-detail-img" :src="this.$assetImgSmall + 'shopnwin/' + images.img_name" alt="">
                          </span>
                        </div>
                        <div class="col-md-8 bdz-background-content">
                          <div class="experience-item-descr slide-descr">
                              <div class="experience-item-title slide-title-sub">{{ article.shopnwin_title }}</div>
                              <p v-html="article.shopnwin_excert"></p>
                              <p v-html="article.shopnwin_desc">   
                              </p>
                          </div>
                          
                          
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 text-right">
                      <div class="a-carousel-nav carousel-nav"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
</template>



<script>
import { setMetaTags } from '../utils/Meta';

export default{
  props: {
    // id : String,
    imgBgShopnwinDetail : String,

    profileName : String,
    profileLogo : String,
  },
  name:'DetailSnw',
  computed: {
    cssImgBgShopnwin() {
      return {
        "background-image": "url('"+ this.$assetImgSmall+'profile/' + this.imgBgShopnwinDetail +"')",
        "background-position": "center",
      };
    }
  },
  methods: {
    async getSNWDetail() {
      await this.$axios
        .get(this.$api + 'snwdetail/' + this.idx)
        .then((response) => {
          // console.log(this.$api + 'articledetail/' + this.idx);
          console.log(response.data.data);
          this.article = response.data.data;


             // set metadata website
             setMetaTags({
              title: this.article.news_title + ' | Pondok Indah Mall',
              metaTags: [
                { name: 'description',content: this.article.news_excert},
                { name: 'robots',content: 'index,follow'},
                { property: 'og:title',  content: this.article.news_title + ' | Pondok Indah Mall'},
                { property: 'og:description',  content: this.article.news_excert},
                { property: 'og:site_name',  content: 'Pondok Indah Mall'},
                { property: 'og:type',  content: 'article'},
                { property: 'og:image:type',  content: 'image/webp'},
                { property: 'og:image',  content: this.$assetImgSmall + 'news/' + this.article.shopnwin_image[0].img_name},
                { property: 'og:url',  content: 'https://pondokindahmall.co.id/shopnwin/' + this.idx},
                { property: 'twitter:card',  content: 'summary_large_image'},
                { property: 'twitter:site',  content: '@pondokindahmall'},
                { property: 'twitter:site:id',  content: '@pondokindahmall'},
                { property: 'twitter:creator',  content: '@pondokindahmall'},
                { property: 'twitter:title',  content: this.article.news_title + ' | Pondok Indah Mall'},
                { property: 'twitter:url',  content: 'https://pondokindahmall.co.id/shopnwin/'+this.idx},
                { property: 'twitter:description',  content: this.article.news_excert},
                { property: 'twitter:image:src',  content: this.$assetImgSmall + 'news/' + this.article.shopnwin_image[0].img_name},

              ]
            })
            // end meta data

      });
    },  
  },
  data() {
    return {
          article : {},
          idx : this.$route.params.id,
        }
  },
  beforeMount(){
    this.getSNWDetail();
  },
  // setup(props){
  //   props.idx = this.$route.params.id;
  // }
}
</script>