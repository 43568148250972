<template>
  
  <div class="section pp-scrollable slide-dark slide-personal-testimonials">
    
            <div class="slide-container">
                
              <div class="slide-bg">
                <div class="slide-photo circle-golden slide-testimonials-circle3 animate-element delay5 fadeInUp"></div>
                
          <div class="slide-photo circle-golden slide-intro-circle1 transformLeft"></div>
          <div class="slide-photo circle-brown slide-intro-circle2 transformRight"></div>>
              </div>
              <div class="container">
               
                
                <div class="row align-items-center animate-element delay5 fadeInDown">
                  <h2 class="slide-title ">Events</h2>  
                </div>

                  <div :key="events.length" class="carousel-testimonial a-carousel-testimonial owl-carousel owl-theme animate-element delay5 fadeInDown">
                      <div v-for="item in events" :key="item.news_id" class="testimonials-item">
                       <div class="row">  


                        <div class="col-md-5 col-sm-10 mb-4">
                          <div class="video-link">
                            
                          <span v-for="images in item.news_image" :key="images.img_id">
                            <a :href="this.$assetImgSmall + 'news/' + images.img_name" data-fancybox class="inside animate-element delay6 fadeIn" >
                              <img class="bdz-facilities-detail-img" :src="this.$assetImgSmall + 'news/' + images.img_name" alt="">
                            </a>
                            </span>
                            
                          </div>
                          <!-- <div class="video-link-descr slide-descr">*Sanfasio Agency’s Stories</div> -->
                        </div>
                        <div class="col-xl-7 col-lg-6 col-md-7">
                        
                                          <div class="row">
                                            
                                            <div class="col-md-10">
                                              <div class="testimonials-item-text slide-title-info">{{ item.news_title }}</div>
                                              <!-- <div class="testimonials-item-author">{{ item.news_date }}</div> -->
                                            </div>
                                            <div class="col-md-2">
                                              <div class="bdz-testimonials-pass">
                                                <span class="bdz-testimonials-pass-button">Past Event</span>
                                              </div>
                                            </div>
                                          </div>
                                          
                                          <br>
                                          <div class="testimonials-desc">
                                              {{ item.news_excert }}
                                          </div>
                                        
                                          <a class="bdz-cityhall-read" :href="'/event/'+item.news_permalink">
                                              <span class="bdz-cityhall-read-button">Read more</span>
                                          </a>
                                          <!-- <div class="testimonials-item-rating">
                                            <i class="lni lni-star-filled"></i>
                                            <i class="lni lni-star-filled"></i>
                                            <i class="lni lni-star-filled"></i>
                                            <i class="lni lni-star-filled"></i>
                                            <i class="lni lni-star-filled"></i>
                                          </div> -->
                                          
                                        </div>
                      </div>
                       </div>
                    
                  </div>
             
                  <!-- <div class="col-md-1 col-xl-2 d-none d-lg-block"></div> -->

              <div class="clearfix"></div> 
              <div class="col-sm-12 col-12 bdz-more-article-wrapper text-right">
                <div class="bdz-more-article-container">
                  <a class="btn btn-success btn-sm bdz-more-article-btn" :href="'/list/event'">See More Event</a>
                </div>
              </div>

              </div>
            </div>
          </div>

  </template>
  
  
 
<script>
export default{
    props: {
      // descSNW : String,
    },
    name:'MainEvents',
    methods: {
      async getEvents() {
        await this.$axios
          .get(this.$api + '/events/6/1')
          .then((response) => {
            // console.log(response.data.data);
            this.events = response.data.data;
        });
      },  
    },
    data() {
      return {
            events : []
          }
    },
    beforeMount(){
      this.getEvents();
    }
}
</script>