<template>
    <MainHeader  :x="x" :facebook="facebook" :instagram="instagram"></MainHeader>
    <div id="content" class="content">
      <div class="homepage-personal a-pagepiling-facilities-detail">
        <DetailFacilities :imgBgFacilitiesDetail="imgBgFacilitiesDetail"></DetailFacilities>
      </div>
    </div>
    <MainFooter :x="x" :facebook="facebook" :instagram="instagram"></MainFooter>
  </template>
  
  <script>
  // @ is an alias to /src
  import MainHeader from '@/components/MainHeader.vue'
  import MainFooter from '@/components/MainFooter.vue'
  import DetailFacilities from '@/components/DetailFacilities.vue'
  
  import { setMetaTags } from '../utils/Meta';
  
  export default {
  name: 'HomeView',
  components: {
    MainHeader,
    DetailFacilities,
    MainFooter,
  },  
  beforeMount() {
    
  },
  methods: {
    async getProfile() {
      await this.$axios
        .get(this.$api + '/profile')
        .then((response) => {
          this.profileName = response.data.data.profile_name;
          this.profileLogo = response.data.data.profile_photo1;
          this.logo = response.data.data.profile_name;
          this.facebook = response.data.data.profile_facebook;
          this.x = response.data.data.profile_twitter;
          this.vimeo = response.data.data.profile_vimeo;
          this.google = response.data.data.profile_google;
          this.pinterest = response.data.data.profile_pinterest;
          this.instagram = response.data.data.profile_instagram;
          this.youtube = response.data.data.profile_youtube;
          this.imgBgFacilitiesDetail = response.data.data.profile_photo8_small;



            // set metadata website
            setMetaTags({
              title: 'Services & Facilities | ' + this.profileName,
              metaTags: [
                { name: 'description',content: 'Services & Facilities Pondok Indah Mall'},
                { name: 'robots',content: 'index,follow'},
                { property: 'og:title',  content: 'Services & Facilities | ' + this.profileName},
                { property: 'og:description',  content: 'Services & Facilities Pondok Indah Mall'},
                { property: 'og:site_name',  content: 'Pondok Indah Mall'},
                { property: 'og:type',  content: 'article'},
                { property: 'og:image:type',  content: 'image/png'},
                { property: 'og:image',  content: this.$assetImg + 'profile/' + this.profileLogo},
                { property: 'og:url',  content: 'https://pondokindahmall.co.id/facilities'},
                { property: 'twitter:card',  content: 'summary_large_image'},
                { property: 'twitter:site',  content: '@pondokindahmall'},
                { property: 'twitter:site:id',  content: '@pondokindahmall'},
                { property: 'twitter:creator',  content: '@pondokindahmall'},
                { property: 'twitter:title',  content: 'Services & Facilities | ' + this.profileName},
                { property: 'twitter:url',  content: 'https://pondokindahmall.co.id/facilities'},
                { property: 'twitter:description',  content: 'Services & Facilities Pondok Indah Mall'},
                { property: 'twitter:image:src',  content: this.$assetImg + 'profile/' + this.profileLogo},

              ]
            })
            // end meta data


      });
    },
   
  },

  data() {
    return {
          imgBgFacilitiesDetail : "",
          
          profileName : "",
          profileLogo : "",
          facebook : "",
          x : "",
          vimeo : "",
          google : "",
          pinterest : "",
          instagram : "",
          youtube : "",
        }
  },

  mounted() {
    this.getProfile();

    let js = document.createElement("script");
    js.setAttribute("src", "/js/script.js");
    document.head.appendChild(js);
  },
  
  }
  </script>
  