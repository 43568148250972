<template>
     <div class="section pp-scrollable bdz-section-slider slide-dark" data-menuanchor="Home" :style="cssImgBgSlider">
        <div class="slide-container">

          <div class="slide-bg">
            <div class="slide-photo circle-golden slide-intro-circle1 transformLeft"></div>
            <div class="slide-photo circle-brown slide-intro-circle2 transformRight"></div>

          </div>
          <div class="bdz-section-main-slider-hover"></div>

          <div :key="featured.length" class="carousel-project-personal bdz-carousel-featured a-carousel-projects owl-carousel owl-theme  animate-element delay1 fadeInUp">
              <div v-for="item in featured " :key="item.news_id" class="carousel-project-item">
                <div class="container">
                  <!-- <div class="slide-num">1</div> -->
                  <div class="row">
                    <div class="col-sm-6 col-10">
                      <h1 class="slide-title ">
                        {{ item.news_title }}
                      </h1>
                      <div class="">
                        <!-- <h3 class="slide-title-sub">
                          {{ item.slider_desc }}
                        </h3> -->
                          <div class="slide-descr slide-descr-intro" >
                            <span v-html="item.news_excert"></span>
                          </div>  

                          
                        <div class="slide-btn">

                          <div class="col-sm-12 col-12 bdz-more-article-wrapper">
                            <div class="bdz-more-article-container">
                              <a class="btn btn-success btn-sm bdz-more-article-btn" :href="'/list/featured'">See More Featured</a>
                            </div>
                          </div>
                          
                          <!-- <button
                            class="btn btn-success"
                            data-toggle="modal"
                            data-target="#send-request"
                          >           
                            Book Now !
                          </button> -->
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-10 bdz-slider-right-content ">
                      <span  v-for="image in item.news_image" :key="image.img_id">
                        <img :src="this.$assetImgSmall + 'news/' + image.img_name"  >
                      </span>
                    </div>
                
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
      
</template>
<script>
export default{
  
    props: {
      imgBgSlider : String,
    },
    name : 'MainSlider',
    computed: {
      cssImgBgSlider() {
        return {
          "background-image": "url('"+ this.$assetImgSmall+'profile/' + this.imgBgSlider +"')",
        };
      }
    },
    methods: {
      async getFeatured() {
        await this.$axios
          .get(this.$api + '/featured/6/1')
          .then((response) => {
            this.featured = response.data.data;
        });
      },  
    },
    data() {
      return {
            featured : [],
          }
    },
    beforeMount(){
      this.getFeatured();
    },
    mounted(){
      // console.log('asd');
    }
}
</script>
