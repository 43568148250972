<template>
  <router-view ref="routerView" @hook:mounted="childComponentMounted"/>
</template>

<style>
</style>


<script>
import EventBus from './EventBus';

export default{
  methods: {
      // call(){alert('asd')},
      callLoggedStatus(id) {
        EventBus.emit('call-child-method', id);
      },
      childComponentMounted() {
      // Panggil metode dari komponen anak setelah komponen dimuat
      this.$refs.routerView.$refs.childRef.callDirectoryDetailFunc();
    }

  },
}
</script>
