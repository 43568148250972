<template>
    <MainHeader  :x="x" :facebook="facebook" :instagram="instagram"></MainHeader>
    <div id="content" class="content">
      <div class="homepage-personal a-pagepiling-snw-detail">
        <DetailFeatured :imgBgFeaturedDetail="imgBgFeaturedDetail" :profileLogo="profileLogo" :profileName="profileName"></DetailFeatured>
      </div>
    </div>
    <MainFooter  :x="x" :facebook="facebook" :instagram="instagram"></MainFooter>
  </template>
  
  <script>
  // @ is an alias to /src
  import MainHeader from '@/components/MainHeader.vue'
  import MainFooter from '@/components/MainFooter.vue'
  import DetailFeatured from '@/components/DetailFeatured.vue'
  // import $ from 'jquery'
  
  export default {
    name: 'FeaturedDetailView',
    components: {
      MainHeader,
      DetailFeatured,
      MainFooter    ,
    },

  methods: {
    async getProfile() {
      await this.$axios
        .get(this.$api + '/profile')
        .then((response) => {
          this.profileName = response.data.data.profile_name;
          this.profileLogo = response.data.data.profile_photo2;
          this.imgBgLanding = response.data.data.profile_photo3_small;
          this.imgBgSlider = response.data.data.profile_photo5_small;
          this.imgBgContact = response.data.data.profile_photo7_small;
          this.imgBgCityHall = response.data.data.profile_photo4_small; // temporary karena belum dibuat di backend
          
          this.imgBgFacilitiesDetail = response.data.data.profile_photo8_small; // temporary karena belum dibuat di backend
          this.imgBgCityhallsDetail = response.data.data.profile_photo9_small; // temporary karena belum dibuat di backend
          this.imgBgWhatsnewsDetail = response.data.data.profile_photo10_small; // temporary karena belum dibuat di backend
          this.imgBgShopnwinDetail = response.data.data.profile_photo11_small; // temporary karena belum dibuat di backend
          this.imgBgEventDetail = response.data.data.profile_photo12_small; // temporary karena belum dibuat di backend
          this.imgBgFeaturedDetail = response.data.data.profile_photo4_small; // temporary karena belum dibuat di backend
          
          this.facebook = response.data.data.profile_facebook;
          this.x = response.data.data.profile_twitter;
          this.vimeo = response.data.data.profile_vimeo;
          this.google = response.data.data.profile_google;
          this.pinterest = response.data.data.profile_pinterest;
          this.instagram = response.data.data.profile_instagram;
          this.youtube = response.data.data.profile_youtube;
      });
    },
  },
    data() {
    return {
          profileName : "",
          profileLogo : "",
          
          imgBgFacilitiesDetail : "",
          imgBgCityhallsDetail : "",
          imgBgWhatsnewsDetail : "",
          imgBgShopnwinDetail : "",
          imgBgEventDetail : "",
          imgBgFeaturedDetail : "",

          facebook : "",
          x : "",
          vimeo : "",
          google : "",
          pinterest : "",
          instagram : "",
          youtube : "",
        }
  },

    mounted() {
      this.getProfile();
      let js = document.createElement("script");
      js.setAttribute("src", "/js/script.js");
      document.head.appendChild(js);
      // $.fn.pagepiling.destroy('all');
    },
  }
  </script>
  