<template>
  
  <MainHeader :x="x" :facebook="facebook" :instagram="instagram"></MainHeader>
  <div id="content" class="content">
    <div class="homepage-personal a-pagepiling">
      <MainLanding :landingTitle="landingTitle" :landingDesc="landingDesc"
        :profileName="profileName" :imgBgLanding="imgBgLanding"></MainLanding>
      <MainSlider :imgBgSlider="imgBgSlider"></MainSlider>
      <MainWhatsnew  :descSNW="descSNW"></MainWhatsnew>
      <MainDirectory></MainDirectory>
      <MainFacilities :imgBgSlider="imgBgSlider"></MainFacilities>
      <MainCityHall :imgBgCityHall="imgBgCityHall"></MainCityHall>
      <MainSnw :descSNW="descSNW"></MainSnw>
      <!-- <MainSosmed></MainSosmed> -->
      <MainEvents></MainEvents>
      <MainWaterpark></MainWaterpark>
      <DetailWaterpark></DetailWaterpark>
      <MainContact 
        :imgBgContact="imgBgContact"
        :company="company"
        :photo="photo"
        :address="address"
        :telp1="telp1"
        :telp2="telp2"
        :fax="fax"
        :zipcode="zipcode"
        :email1="email1"
        :email2="email2"
        :city="city"
        :country="country"
      ></MainContact>
    </div>
  </div>
  <MainFooter :x="x" :facebook="facebook" :instagram="instagram"></MainFooter>
</template>



<script>
import { setMetaTags } from '../utils/Meta';

// @ is an alias to /src
import MainHeader from '@/components/MainHeader.vue'
import MainLanding   from '@/components/MainLanding.vue'
import MainSlider from '@/components/MainSlider.vue'
import MainFooter from '@/components/MainFooter.vue'
import MainDirectory from '@/components/MainDirectory.vue'
import MainFacilities from '@/components/MainFacilities.vue'
import MainCityHall from '@/components/MainCityHall.vue'
import MainWhatsnew from '@/components/MainWhatsnew.vue'
import DetailWaterpark from '@/components/DetailWaterpark.vue'
import MainSnw from '@/components/MainSnw.vue'
import MainWaterpark from '@/components/MainWaterpark.vue'
import MainEvents from '@/components/MainEvents.vue'
import MainContact from '@/components/MainContact.vue'
// import MainSosmed from '@/components/MainSosmed.vue'

export default {
  name: 'HomeView',
  components: {
    MainHeader,
    MainLanding,
    MainSlider,
    MainFooter,
    MainDirectory,
    MainSnw,
    MainFacilities,
    MainCityHall,
    MainWhatsnew,
    MainWaterpark,
    DetailWaterpark,
    MainEvents,
    MainContact,
    // MainSosmed,
  },  
  beforeMount() {
    
  },
  methods: {
    async getProfile() {
      await this.$axios
        .get(this.$api + '/profile')
        .then((response) => {
          this.profileName = response.data.data.profile_name;
          this.profileLogo = response.data.data.profile_photo1;

          this.imgBgLanding = response.data.data.profile_photo3_small;
          this.imgBgSlider = response.data.data.profile_photo5_small;
          this.imgBgContact = response.data.data.profile_photo7_small;
          this.imgBgCityHall = response.data.data.profile_photo4_small; // temporary karena belum dibuat di backend
          
          this.descSNW = response.data.data.profile_desc5;
          
          this.landingTitle = response.data.data.profile_desc1;
          this.landingDesc = response.data.data.profile_desc2;
          
          this.logo = response.data.data.profile_name;
          this.company = response.data.data.profile_name;
          this.photo = response.data.data.profile_photo2_small;
          this.address = response.data.data.profile_address1;
          this.telp1 = response.data.data.profile_phone1;
          this.telp2 = response.data.data.profile_phone2;
          this.fax = response.data.data.profile_fax;
          this.zipcode = response.data.data.profile_zipcode ;
          this.email1 = response.data.data.profile_email1;
          this.email2 = response.data.data.profile_email2;
          this.city = response.data.data.profile_city;
          this.country = response.data.data.profile_country;

          this.facebook = response.data.data.profile_facebook;
          this.x = response.data.data.profile_twitter;
          this.vimeo = response.data.data.profile_vimeo;
          this.google = response.data.data.profile_google;
          this.pinterest = response.data.data.profile_pinterest;
          this.instagram = response.data.data.profile_instagram;
          this.youtube = response.data.data.profile_youtube;


            // set metadata website
            setMetaTags({
              title: this.profileName,
              metaTags: [
                { name: 'description',content: 'Pondok Indah Mall'},
                { name: 'robots',content: 'index,follow'},
                { property: 'og:title',  content: this.profileName},
                { property: 'og:description',  content: 'Pondok Indah Mall'},
                { property: 'og:site_name',  content: 'Pondok Indah Mall'},
                { property: 'og:type',  content: 'article'},
                { property: 'og:image:type',  content: 'image/png'},
                { property: 'og:image',  content: this.$assetImg + 'profile/' + this.profileLogo},
                { property: 'og:url',  content: 'https://pondokindahmall.co.id/'},
                { property: 'twitter:card',  content: 'summary_large_image'},
                { property: 'twitter:site',  content: '@pondokindahmall'},
                { property: 'twitter:site:id',  content: '@pondokindahmall'},
                { property: 'twitter:creator',  content: '@pondokindahmall'},
                { property: 'twitter:title',  content: this.profileName},
                { property: 'twitter:url',  content: 'https://pondokindahmall.co.id/'},
                { property: 'twitter:description',  content: 'Pondok Indah Mall'},
                { property: 'twitter:image:src',  content: this.$assetImg + 'profile/' + this.profileLogo},

              ]
            })
            // end meta data

      });
    },
  },

  data() {
    return {
          profileName : "",
          profileLogo : "",

          imgBuilding : "",
          imgBgLanding : "",
          imgBgSlider : "",
          imgBgContact : "",
          imgBgCityHall : "",
          
          imgBgFacilitiesDetail : "",
          imgBgCityhallsDetail : "",
          imgBgWhatsnewsDetail : "",
          imgBgShopnwinDetail : "",
          imgBgEventDetail : "",

          landingTitle : "",
          landingDesc : "",

          descSNW : "",

          company  : "",
          photo  : "",
          address : "",
          telp1 : "",
          telp2 : "",
          fax : "",
          zipcode : "",
          email1 : "",
          email2 : "",
          city : "",
          country : "",

          facebook : "",
          x : "",
          vimeo : "",
          google : "",
          pinterest : "",
          instagram : "",
          youtube : "",
        }
  },

  mounted() {
    this.getProfile();

    let js = document.createElement("script");
    js.setAttribute("src", "/js/script.js");
    document.head.appendChild(js);

   
  },
}


</script>


