<template>
     <div class="section pp-scrollable slide-personal-awards" data-menuanchor="shopnwin" >
        <div class="slide-container">
          <div class="slide-bg">
            <div
              class="slide-photo circle-green slide-awards-circle1 transformLeft"
            ></div>
            <div
              class="slide-photo circle-brown slide-awards-circle2 transformRight"
            ></div>
          </div>
          <div class="container">
            <div
              class="row align-items-center animate-element delay5 fadeInDown"
            >
              <div class="col-md-12">
                <h2 class="slide-title mb-md-0">SHOP & WIN</h2>
              </div>
              
            </div>
            <div class=" award-list animate-element delay5 fadeInUp">

              <div :key="snw.length" class=" bdz-snw-content animate-element delay5 fadeInUp">
                      
                      
                <div v-for="(item) in snw" :key="item.shopnwin_id" class="col-sm-12 col-lg-12">
                        <div class="award-item">
                          <a class="award-item-logo" :href="'/shopnwin/' + item.shopnwin_permalink">
                            <div class="bdz-award-item" v-for="images in item.shopnwin_image" :key="images.img_id">
                              <img :src="this.$assetImgSmall + 'shopnwin/' + images.img_name" >
                            </div>
                          </a>
                          <div class="testimonials-item bdz-cityhall-description" >
                            <div class="testimonials-item-text slide-title-info" >{{ item.shopnwin_title }}</div><!-- <div class="testimonials-item-author">{{ item.news_date }}</div> --><br>
                              <div class="testimonials-desc">
                                {{ extractContent(item.shopnwin_desc).substring(0,350) }}
                              </div>
                              
                                <a class="bdz-cityhall-read" :href="'/shopnwin/' + item.shopnwin_permalink" >
                                  <span class="bdz-cityhall-read-button">Read more</span></a></div>
                         
                        </div>
                      </div>
                    <!-- <div class="video-link-descr slide-descr">*Sanfasio Agency’s Stories</div> -->
              </div>
              <!-- <div class="col-md-1 col-xl-2 d-none d-lg-block"></div> -->



              <div v-for="item in snw" :key="item.shopnwin_id" class="col-lg-3">
              
              </div>
            
            </div>
          </div>
        </div>
      </div>
</template>

<script>

export default{
    props: {
      descSNW : String,
    },
    name:'MainSnw',
    methods: {
      async getShopnwin() {
        await this.$axios
          .get(this.$api + '/snw')
          .then((response) => {
            console.log(response.data.data);
            this.snw = response.data.data;

            this.pages = Math.ceil(this.snw.length/4)+1;

        });
      },
      extractContent(html) {
          return new DOMParser()
              .parseFromString(html, "text/html")
              .documentElement.textContent;
      }  
    },
    data() {
      return {
            snw : [],
            pages:0,
          }
    },
    mounted(){
      this.getShopnwin();
    }
}
</script>