<template>
    
 
    <div class="section pp-scrollable bdz-section-directory-detail  slide-dark slide-personal-experience" :style="cssimgBg">
            <div class="slide-container">
              
          <div class="slide-bg"></div>
          <div class="bdz-section-directory-detail-hover"></div>

              <div class="container">
                <div class="row align-items-center mb-4 animate-element delay5 fadeInDown">
                  <div class="col-12">
                    <div class="bdz-directory-detail-back-button">
                        <a class="bdz-directory-detail-back-link" href="/#Contact">Back to Home</a>
                    </div>
                    <h2 class="slide-title mb-0">search <i>"{{ this.key }}"</i>
                        
                    </h2>
                   
                  </div>
                
                </div>
                <div class="animate-element delay5 fadeInUp bdz-background-content">

                    <div :key="search.length" class="experience-list">
                        <div v-for="item in search" :key="item.data_id">
                        <a  class="experience-item experience-item-search" 
                            :href="item.data_type_id == 1 ? '/featured/' + item.data_permalink : 
                                  (item.data_type_id == 2 ? '/cityhall/' : 
                                  (item.data_type_id == 3 ? '/whatsnew/' : 
                                  (item.data_type_id == 4 ? '/event/' : 
                                  (item.data_type_id == 5 ? '/rides/' : 
                                  (item.data_type_id == 6 ? '/shopnwin/' :
                                  (item.data_type_id == 7 ? '/facilities/' : '/directory/' )))))) 
                              + item.data_permalink">
                            <div class="row bdz-career-item">
                            <div class="col-md-12">
                                <span class="badge badge-primary">{{item.data_type}}</span>  
                                <!-- <small class="badge     bdz-slide-title-search-date">{{ item.data_date }}</small> -->
                                <div class="experience-item-title slide-title-sub-search">{{ item.data_title }}</div>
                                <div class="experience-item-descr slide-descr experience-item-descr-search" v-html="item.data_short"></div>
                            </div>
                            </div>
                            <hr/>
                        </a>
                      </div>
                      
                    </div>
                   
                    
                </div>
              </div>
            </div>
          </div>
</template>


<script>

export default{
  props: {
    imgBgCareer : String,
    },
    name : 'MainSearch',
    computed: {
      cssimgBg() {
        return {
          "background-image": "url('"+ this.$assetImgSmall+'profile/' + this.imgBgCareer +"')",
          "background-position": "center",
        };
      },
      cssImgBgFacilities() {
        return  img => ({
          "background-image": "url('"+ this.$assetImgSmall+'facilities/' + img +"')",
          "background-position": "center",
        });
      },
     
    },
    methods: {
      async getSearch() {
        await this.$axios
          .get(this.$api + '/search/' + this.key)
          .then((response) => {
            this.search = response.data.data;

        });
      },  
     
    },
    data() {
      return {
             key : this.$route.params.key,
             search : []
          }
    },
    beforeMount(){
      this.getSearch();
    },
 
}

</script>