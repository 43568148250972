<template >
    <MainHeader  :x="x" :facebook="facebook" :instagram="instagram"></MainHeader>
    <div id="content" class="content" ref="childRef">
      <div class="homepage-personal a-pagepiling-directory-detail">
        <DetailDirectory ref="directoryDetails" :imgBgDirectoryDetail="imgBgDirectoryDetail"></DetailDirectory>
      </div>
    </div>
    <MainFooter  :x="x" :facebook="facebook" :instagram="instagram"></MainFooter>
  </template>
  
  <script>
  // @ is an alias to /src
  import MainHeader from '@/components/MainHeader.vue'
  import MainFooter from '@/components/MainFooter.vue'
  import DetailDirectory from '@/components/DetailDirectory.vue'
  
  import { setMetaTags } from '../utils/Meta';
// import $ from 'jquery'
  
  export default {
    name: 'DirectoryDetailView',
    components: {
      MainHeader,
      DetailDirectory,
      MainFooter    ,
    },
    methods: {
      async getProfile() {
        await this.$axios
          .get(this.$api + '/profile')
          .then((response) => {
            // console.log('bdz :', response);

            this.profileName = response.data.data.profile_name;
            this.profileLogo = response.data.data.profile_photo1;
            this.imgBgLanding = response.data.data.profile_photo3_small;
            this.imgBgSlider = response.data.data.profile_photo5_small;
            this.imgBgContact = response.data.data.profile_photo7_small;
            this.imgBgCityHall = response.data.data.profile_photo4_small; // temporary karena belum dibuat di backend
            
            this.imgBgDirectoryDetail = response.data.data.profile_photo6_small; // temporary karena belum dibuat di backend
            this.imgBgFacilitiesDetail = response.data.data.profile_photo8_small; // temporary karena belum dibuat di backend
            this.imgBgCityhallsDetail = response.data.data.profile_photo9_small; // temporary karena belum dibuat di backend
            this.imgBgWhatsnewsDetail = response.data.data.profile_photo10_small; // temporary karena belum dibuat di backend
            this.imgBgShopnwinDetail = response.data.data.profile_photo11_small; // temporary karena belum dibuat di backend
            this.imgBgEventDetail = response.data.data.profile_photo12_small; // temporary karena belum dibuat di backend
            
            this.facebook = response.data.data.profile_facebook;
            this.x = response.data.data.profile_twitter;
            this.vimeo = response.data.data.profile_vimeo;
            this.google = response.data.data.profile_google;
            this.pinterest = response.data.data.profile_pinterest;
            this.instagram = response.data.data.profile_instagram;
            this.youtube = response.data.data.profile_youtube;

            // set metadata website
            setMetaTags({
              title: 'Mall Directory | ' + this.profileName,
              metaTags: [
                { name: 'description',content: 'Mall directory Pondok Indah Mall'},
                { name: 'robots',content: 'index,follow'},
                { property: 'og:title',  content: 'Mall Directory | '+ this.profileName},
                { property: 'og:description',  content: 'Mall directory Pondok Indah Mall'},
                { property: 'og:site_name',  content: 'Pondok Indah Mall'},
                { property: 'og:type',  content: 'article'},
                { property: 'og:image:type',  content: 'image/png'},
                { property: 'og:image',  content: this.$assetImg + 'profile/' + this.profileLogo},
                { property: 'og:url',  content: 'https://pondokindahmall.co.id/directory'},
                { property: 'twitter:card',  content: 'summary_large_image'},
                { property: 'twitter:site',  content: '@pondokindahmall'},
                { property: 'twitter:site:id',  content: '@pondokindahmall'},
                { property: 'twitter:creator',  content: '@pondokindahmall'},
                { property: 'twitter:title',  content: 'Mall Directory | '+ this.profileName},
                { property: 'twitter:url',  content: 'https://pondokindahmall.co.id/directory'},
                { property: 'twitter:description',  content: 'Mall directory Pondok Indah Mall'},
                { property: 'twitter:image:src',  content: this.$assetImg + 'profile/' + this.profileLogo},

              ]
            })
            // end meta data

      
        });
      },

    callDirectoryDetailFunc(){
      alert('dari directory detail');
      }
  },
    data() {
    return {
          profileName : "",
          profileLogo : "",
          
          imgBgDirectoryDetail : "",
          imgBgFacilitiesDetail : "",
          imgBgCityhallsDetail : "",
          imgBgWhatsnewsDetail : "",
          imgBgShopnwinDetail : "",
          imgBgEventDetail : "",

          facebook : "",
          x : "",
          vimeo : "",
          google : "",
          pinterest : "",
          instagram : "",
          youtube : "",
        }
  },

    mounted() {
      this.getProfile();
      let js = document.createElement("script");
      js.setAttribute("src", "/js/script.js");
      document.head.appendChild(js);
      
    

    },
  }
  </script>
  