<template>
    
    <div class="section pp-scrollable" data-menuanchor="Directory">
        <div class="slide-container">
          <div class="slide-bg">
            <div class="slide-photo circle-light slide-services-circle2 transformLeft"></div>
            <div class="slide-photo circle-brown slide-services-circle1 transformRight"></div>
          </div>
          <div class="container">
            <h2 class="slide-title slide-title-personal-services slide-title-lg">MALL DIRECTORY</h2>
            <div class="row">
              <div class="col-md-4">
                <div class="animate-element delay5 fadeInLeft">
                  <div class="bdz-directory-menu">
                    <div class="bdz-directory-search-content">
                      <div class="bdz-directory-search-input">
                        <input type="text" class="text" v-model="searchText" v-on:keyup="submitSearch"  placeholder="Search by Shop Name">
                        <i class="fa fa-search"></i>
                      </div>
                    </div>
                    <div class="directory-category-content">
                      <ul>
                        <li index="0" 
                            @click="setCategory('99')"
                            @click.stop="toggleActive(0)"
                            v-bind:class="{ 'directory-category-content-active': (this.indexSelected === 0 ? true : false) }">All Categories  
                          </li>  
                        <li class="" v-for="(item, index) in category" :index="index+1" :key="item.category_id" 
                          @click="setCategory(item.category_id)" 
                          v-bind:class="{ 'directory-category-content-active': (this.indexSelected === (index+1) ? true : false) }"
                          @click.stop="toggleActive(index+1)">
                          <!-- <img :src="this.$assetImgSmall + 'category/' + item.category_photo_bg" alt="" class="bdz-directory-category-background"> -->
                          <span>{{ item.category_name }}</span>
                        </li>  

                      </ul>
                    </div>
                  </div>   
                  <div class="d-md-none">
                    <div class="slide-title-sub slide-title-sub-sm">
                      Search
                    </div>
                   
                  </div>
                </div>
              </div>
              <div class="col-md-8 animate-element  delay5 fadeInDown">
                <div class="row  ">
                  <div v-for="item in tenant" :key="item.tenant_id" class="col-6 col-md-3 col-xl-3 p-2">
                    <div  class="bdz-container-directory-item">
                      <a :href="'/directory/' + item.tenant_permalink">
                        <img :src="this.$assetImg + 'tenant/' + (item.tenant_photo!= '' ? item.tenant_photo : item.tenant_logo)" :alt="item.tenant_name" class="bdz-directory-logo-item-main">
                      </a>

                      <!-- <RouterLink to="/directory-detail"></RouterLink> -->
                      <div class="bdz-directory-item-tenant-name">
                         <b>{{ item.tenant_name }}</b>
                      </div>      
                      <div class="bdz-directory-item-tenant-desc">
                        <span :v-html="item.tenant_desc"></span>
                        {{ item.tenant_location }}
                        {{ item.tenant_floor }}
                        {{ item.tenant_area }}
                        {{ item.block_name }}
                        
                        <br>
                        <br>
                        <div class="bdz-directory-item-tenant-phone">
                          <a :href="'tel:' + item.tenant_phone">Call Now</a>
                        </div>
                      </div>

                      <div class="clearfix"></div>
                    </div>
                  </div>
                </div>

            

              <div class="row bdz-directory-pagination-wrapper">
                <div class="bdz-directory-pagination-container">
                  <nav aria-label="Page navigation example">
                    <ul class="pagination">
                      <li class="page-item"><a class="page-link" href="#"
                        @click="prev"
                        v-bind:class="{ 'd-none': this.page === 1 }"
                        >Previous</a></li>
                      <li class="page-item"><a class="page-link" href="#"
                        @click="next"
                        v-bind:class="{ 'd-none': tenant.length < 8 }"
                        >Next</a></li>
                    </ul>
                  </nav>
                </div>
              </div>
              </div>
            </div>

            
          </div>
        </div>
      </div>
</template>

<script>

export default{
    name:'MainDirectory',
    // props : [active, index],
    methods: {
      async getCategory() {
        await this.$axios
          .get(this.$api + '/category')
          .then((response) => {
            this.category = response.data.data;
        });
      },  
      async getTenant(key) {
        key = key == '' ? 'all' : key;
        // console.log(this.$api + 'tenantByKey/'+ key + '/' + this.cat + '/'+ this.limit + '/' + this.page);
        await this.$axios
          .get(this.$api + 'tenantByKey/'+ key + '/'+ this.cat + '/' + this.limit + '/' + this.page)
          .then((response) => {
            this.tenant = response.data.data;
            // console.log(this.tenant);
        });
      }, 
      submitSearch: function(e) {
        if (e.keyCode === 13)
          this.page = 1;
          this.getTenant(this.searchText);

      },
      setCategory(cat){
        this.cat = cat;
        this.page = 1
        this.getTenant(this.searchText);
      }, 
      toggleActive(index) {
        this.indexSelected = index
      },
      next(){
        this.page = this.page +1;
        this.getTenant(this.searchText);

      },
      prev(){
        if (this.page > 1) {
          this.page = this.page - 1;  
          this.getTenant(this.searchText);
        }else{
          this.page = 1;
        }
        
        
      }
    },
    data() {
      return {
            category : [],
            tenant : [],
            searchText : '',
            page : 1,
            limit : 8,
            cat : 99,
            isActive:false,
            indexSelected:0,
          }
    },
    mounted(){
      this.getCategory();
      this.getTenant('');
    }
}
</script>