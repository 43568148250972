import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import DirectoryDetailView from '../views/DirectoryDetailView.vue'
import RidesDetailView from '../views/RidesDetailView.vue'
import CareerView from '../views/CareerView.vue'
import FacilitiesDetailView from '../views/FacilitiesDetailView.vue'
import CityHallDetailView from '../views/CityHallDetailView.vue'
import WhatsnewDetailView from '../views/WhatsnewDetailView.vue'
import FeaturedDetailView from '../views/FeaturedDetailView.vue'
import SnwDetailView from '../views/SnwDetailView.vue'
import EventDetailView from '../views/EventDetailView.vue'
import SearchView from '../views/SearchView.vue'
import ListView from '../views/ListView.vue'
import { setMetaTags } from '../utils/Meta'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/directory/:id?',
    name: 'DirectoryDetailView',
    component: DirectoryDetailView,
    props: true,
  },
  {
    path: '/rides/:id?',
    name: 'RidesDetailView',
    component: RidesDetailView,
    props: true,

  },
  {
    path: '/career',
    name: 'CareerView',
    component: CareerView
  },
  // {
  //   path: '/facilities/:permalink?',
  //   name: 'facilities',
  //   component: FacilitiesDetailView,
  //   props: true
  // },
  {
    path: '/facilities/:permalink?/:type',
    name: 'facilities',
    component: FacilitiesDetailView,
    props: true
  },
  {
    path: '/cityhall/:id?',
    name: 'CityHallDetailView',
    component: CityHallDetailView,
    props: true,
  },
  {
    path: '/whatsnew/:id?',
    name: 'WhatsnewDetailView',
    component: WhatsnewDetailView,
    props: true,
  },
  {
    path: '/featured/:id?',
    name: 'FeaturedDetailView',
    component: FeaturedDetailView,
    props: true,
  }
  ,
  {
    path: '/search/:key?',
    name: 'SearchView',
    component: SearchView,
    props: true,
  }
  ,
  {
    path: '/shopnwin/:id?',
    name: 'SnwDetailView',
    component: SnwDetailView,
    props: true,

  } ,
  {
    path: '/event/:id?',
    name: 'EventDetailView',
    component: EventDetailView,
    props: true,

  },
  {
    path: '/list/:type?',
    name: 'ListView',
    component: ListView,
    props: true,

  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.afterEach((to, from) => {
  const componentMeta = to.matched.some(record => record.meta);
  if (!componentMeta) {
    setMetaTags({
      title: 'Default Title',
      metaTags: [
        {
          name: 'description',
          content: 'Default description.'
        }
      ]
    });
  }
})


export default router
