<template>
    <div class="section pp-scrollable bdz-waterpark-detail" data-menuanchor="Waterpark">
     

            <div class="bdz-wp-detail-bg-top animate-element delay1  fadeInDown">
                <img src="../assets/img/bg-wp-det-top.png" alt="">

                <div class="bdz-wp-detail-ban">
                    <a href="/rides/lazy-river"  class="bdz-wp-detail-ban-1">
                        <img src="../assets/img/ban-green.png" alt="">
                    </a>
                    <a href="/rides/olympic-pool"  class="bdz-wp-detail-ban-2">
                        <img src="../assets/img/ban-pink.png" alt="">
                    </a>
                    <a href="/rides/whizzard-slide"  class="bdz-wp-detail-ban-3">
                        <img src="../assets/img/ban-yellow.png" alt="">
                    </a>
                    <a href="/rides/aqua-play"  class="bdz-wp-detail-ban-4">
                        <img src="../assets/img/ban-blue.png" alt="">
                    </a>
                    <a href="/rides/tube-slide"  class="bdz-wp-detail-ban-5">
                        <img src="../assets/img/ban-red.png" alt="">
                    </a>
                    <a href="/rides/wave-pool"  class="bdz-wp-detail-ban-6">
                        <img src="../assets/img/ban-purple.png" alt="">
                    </a>
                    <a href="/rides/flow-rider"  class="bdz-wp-detail-ban-7">
                        <img src="../assets/img/ban-orange.png" alt="">
                    </a>
                </div>
                
                <div class="bdz-wp-detail-bg-bottom animate-element delay1 fadeInUp">
                    <img src="../assets/img/bg-wp-det-bot.png" alt="">
                </div> 
            
            </div> 
           
            <div class="bdz-wp-menu-wrapper  animate-element delay3 fadeInDown">
              <div class="bdz-wp-menu-container">
                <img src="../assets/img/logo-wp.png" alt="" class="bdz-wp-menu-logo">
                <div class="clearfix"></div>
                <!-- <ul>
                  <li>Rides</li>
                  <li>Info</li>
                  <li>Reservation</li>
                  <li>Buy Ticket</li>
                  <li>News</li>
                </ul> -->
              </div>
            </div>
       


        
   </div>

</template>


<script>
export default{
 name: 'MainWaterpark'
}
</script>