<template>
    <div class="section pp-scrollable bdz-section-landing slide-dark slide-dark-footer" :style=cssImgBgLanding data-menuanchor="Home">
       <div class="slide-container">
         <div class="slide-bg">

          <div class="slide-photo circle-golden slide-intro-circle1 transformLeft"></div>
          <div class="slide-photo circle-brown slide-intro-circle2 transformRight"></div>
           <!-- <div class="slide-photo slide-intro-man animate-element delay5 fadeInUp"  :style="cssImgBuilding"></div> -->

          <div class="bdz-landing-building-container">
            <img src="../assets/img/landing1.png" class="bdz-landing-building-img-1 animate-element delay5 fadeRight transformLeft effect-bdz-test " alt="">
            <img src="../assets/img/landing2.png" class="bdz-landing-building-img-2 animate-element delay3 fadeUp transformUp effect-bdz-test" alt="">
            <img src="../assets/img/landing3.png" class="bdz-landing-building-img-3 animate-element delay6 fadeLeft transformRight effect-bdz-test" alt="">
          </div>
         </div>
         <div class="bdz-section-main-slider-hover"></div>

         <div class="container">
           <!-- <div class="slide-num">1</div> -->
           <div class="row">
             <div class="col-sm-6 col-10">
               <h1 class="slide-title animate-element delay5 fadeInDown" v-html="landingTitle"></h1>
               <div class="animate-element delay5 fadeInUp">
                      <div class="slide-descr slide-descr-intro" v-html="landingDesc" ></div>  
                  </div>
             </div>
           </div>
         </div>
       </div>
     </div>
</template>
<script>
// import axios from 'axios'


export default{
  
    props: {
      profileName: String,
      imgBuilding: String,
      imgBgLanding : String,
      landingDesc : String,
      landingTitle : String,
    },

    name : 'MainLanding',
  
    computed: {
      cssImgBuilding() {
        return {
          "background-image": "url('"+ this.$assetImgSmall+'profile/' + this.imgBuilding +"')",
        };
      },
      cssImgBgLanding() {
        return {
          "background-image": "url('"+ this.$assetImgSmall+'profile/' + this.imgBgLanding +"')",
        };
      }
    },
    mounted(){
    
    }
}

</script>
