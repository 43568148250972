<template>
    <div class="section pp-scrollable slide-personal-awards" data-menuanchor="shopnwin" >
       <div class="slide-container">
         <div class="slide-bg">
           
          <div class="slide-photo circle-light slide-services-circle2 transformLeft"></div>
            <div class="slide-photo circle-brown slide-services-circle1 transformRight"></div>
         </div>
         <div class="container">
           <div class="row align-items-center animate-element delay5 fadeInDown">
               <h2 class="slide-title">What's New on PIM</h2>
           </div>
           <div class="row wnew-list animate-element delay5 fadeInUp">


            <div :key="articles.length" class="bdz-whatsnew-content animate-element delay5 fadeInUp">
                <div class="row" v-for="page in pages" :key="page">
                  <span v-for="(item,index) in articles" :key="item.shopnwin_id" class="col-lg-4 p-2 m-0 bdz-whatsnew-item">
                    <a v-if="index >= ((page-1) * 6) && index < (page * 6)" class="wnew-item" :href="'/whatsnew/' + item.news_permalink">
                      <div class="wnew-item-logo">
                        <div class="bdz-award-item" v-for="images in item.news_image" :key="images.img_id">
                          <img :src="this.$assetImgSmall + 'news/' + images.img_name" > 
                          <div class="bdz-wnew-desc">
                                <div class="bdz-wnew-desc-text">
                                  {{ item.news_title }}
                                </div> 
                                <div class="clearfix"></div>
                                <div class="bdz-wnew-desc-text-readmore">
                                  Read more
                                </div> 
                            </div>                               
                        </div>
                      
                      </div>
                    </a>
                  </span>
                </div>
              </div>
              <!-- <div class="col-md-1 col-xl-2 d-none d-lg-block"></div> -->


              <div class="clearfix"></div> 
              <br>
              <div class="col-sm-12 col-12 mt-4 bdz-more-article-wrapper text-center">
                <div class="bdz-more-article-container">
                  <a class="btn btn-success btn-sm bdz-more-article-btn" :href="'/list/whatsnew'">See More What's New</a>
                </div>
              </div>
        
         
           </div>
         </div>
       </div>
     </div>
</template>

<script>
export default{
   props: {
    //  descSNW : String,
   },
   name:'MainWhatsnew',
   methods: {
     async getWhatsnew() {
       await this.$axios
         .get(this.$api + '/whatsnew/6/1')
         .then((response) => {
           this.articles = response.data.data;
            this.pages = Math.ceil(this.articles.length/6)+1;

           
       });
     },  
   },
   data() {
     return {
           articles : [],
           pages : 0,
         }
   },
   beforeMount(){
     this.getWhatsnew();
   }
}
</script>