
export function setMetaTags(meta) {
    document.title = meta.title || 'Default Title';
  
    const existingMetaTags = document.querySelectorAll('meta[name="description"], meta[property="og:description"]');
    existingMetaTags.forEach(tag => tag.remove());
  
    if (meta.metaTags) {
      meta.metaTags.forEach(tag => {
        const metaElement = document.createElement('meta');
        Object.keys(tag).forEach(key => {
          metaElement.setAttribute(key, tag[key]);
        });
        document.head.appendChild(metaElement);
      });
    }
  }

