<template>
     <div class="section pp-scrollable slide-dark   bdz-section-contact" data-menuanchor="Contact"  :style="cssImgBgContact">
        <div class="slide-container">
          <div class="slide-bg">
          <div class="slide-photo circle-light slide-services-circle2 transformLeft"></div>
            <div class="slide-photo circle-brown slide-services-circle1 transformRight"></div>
          </div>
          <div class="bdz-section-contact-hover"></div>
          <div class="container">
            <div class="row">
              <div class="slide-title-info animate-element delay5 ">
                    <h2 class="slide-title">Contact Us</h2>
                </div>
            </div>
            <div class="row">

              
                <div class="clearfix"></div> 
              <div class="col-lg-5 animate-element delay7  bdz-background-content fadeInUp">
                <div class="contact-personal-card">
                
                  <div
                    class="contact-personal-card-detail animate-element delay7 fadeInUp"
                  >
                    <div class="row">  
                      <!-- <img :src="this.$assetImgSmall + 'profile/' + photo"  > -->
                    </div> 
                    <div class="row">
                      <div class="col-xl-7 col-lg-10">
                        <!-- <div class="title-mini">contact info</div>   -->
                        <p class="mt-3"> 
                          <b>{{ company }}</b>
                          <br>
                          {{ address }}  
                        </p>
                          <br>
                        <p> 
                          <b>Email :</b>
                          <br>
                          <br>
                          Leasing inquiry:
                          <br>
                          {{ email1}}
                          <br>
                          <br>
                          Promotion & Casual Leasing:
                          <br>
                          {{ email2 }}
                        </p>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div class="col-lg-7 mb-5 mb-lg-0 pl-5  bdz-background-content animate-element delay7 fadeInUp">
               

                <form class="contact-personal-form a-ajax-form animate-element delay7 fadeInUp" @submit.prevent="handleSubmit">
                  <div class="form-group form-group-material a-form-group">

                    <input
                    placeholder="Full name"
                      type="text"
                      class="form-control"
                      name="name"
                      required
                      v-model="name"
                    />
                  </div>
                  <div class="form-group form-group-material a-form-group">
                    <input
                    placeholder="Email"
                      type="email"
                      class="form-control"
                      name="email"
                      required
                      v-model="email"
                    />
                  </div>
                  <div class="form-group form-group-material a-form-group">
                    <textarea
                      placeholder="Message"
                      class="form-control"
                      name="description"
                      required
                      v-model="description"
                    />
                  </div>
                  
                 
                  <div class="message text-success success-message">
                    Your message is successfully sent...
                  </div>
                  <div class="message text-danger error-message">
                    Sorry something went wrong
                  </div>
                  <!-- pastikan data-callback sesuai dengan nama fungsi callback -->
                  <div id="recaptcha" ref="recaptcha" :data-sitekey="siteKey"></div>

                  <div class="control-btn">
                    <button class="btn btn-success" type="submit">
                      Send message
                    </button>
                  </div>
                </form>
              </div>
             
            </div>
          </div>
        </div>
      </div>
</template>




<script>


export default{
  
    props: {
      imgBgContact : String,
      company  : String,
      photo  : String,
      address : String,
      telp1 : String,
      telp2 : String,
      fax : String,
      zipcode : String,
      email1 : String,
      email2 : String,
      city : String,
      country : String,

    },
    name:'MainContact',
    computed: {
      cssImgBgContact() {
        return {
          "background-image": "url('"+ this.$assetImgSmall+'profile/' + this.imgBgContact +"')",
        };
      }
    },
    data() {
      return {
            recaptchaToken : "",
            siteKey : this.$siteKeyCaptcha,
            name: '',
            email: '',
            description: '',
            isRecaptchaRendered: false,
            recaptchaWidget: null, // Menyimpan referensi widget reCAPTCHA

          }
    },
    created() {
      this.$nextTick(() => {

        setTimeout(() => {
          this.initializeRecaptcha();
          // grecaptcha.render('recaptcha-main'); // 'recaptcha-main' is the id that was assigned to the widget
        }, 2000)

      })
      // if(window.grecaptcha)
        // window.grecaptcha.reset();
    },

    methods:{
      onVerify: function(token) { 
        this.recaptchaToken = token;
      },

      initializeRecaptcha() {
         // Render reCAPTCHA widget jika belum dirender sebelumnya
          if (typeof window !== 'undefined' && typeof window.grecaptcha !== 'undefined') {
            window.grecaptcha.ready(() => {
              window.grecaptcha.render(this.$refs.recaptcha, {
                sitekey: this.siteKey,
                callback: this.onVerify,
              });
            });
          } else {
            console.error('reCAPTCHA library not loaded.');
          }
      },

      handleSubmit() {
        
        if (this.recaptchaToken === '') {
          alert('Mohon cek kembali captcha pada form anda');
          return;
        }

        // Kirim data form dan token reCAPTCHA ke server untuk verifikasi
        // console.log('Form submitted with reCAPTCHA token:', this.recaptchaToken);
        // alert('Form submitted!');
        const postData = {
          token: this.recaptchaToken,
          name: this.name,
          email: this.email,
          description: this.description,
        };

        // this.myString.replace(/[^a-zA-Z0-9]/g, '');
        // console.log(postData);

        this.$axios
        // .get(this.$api + 'contact/'+ this.name + '/' + this.email + '/' + this.description + '/' + this.recaptchaToken )
        // .post(this.$api + 'contact/', postData, )
        .post(this.$api + 'contact', postData, {
            headers: {
                'Content-Type': 'text/plain',
            }
        })
        .then(response => {
          // console.log('Form submitted with response:', response.data);
          alert('Pesan yang anda kirim telah kami terima dengan baik, terima kasih');

          this.recaptchaToken = '';
          this.name = '';
          this.email = '';
          this.description = '';
          window.grecaptcha.reset();
        })
        .catch(error => {
          // console.error('Error submitting form:', error);
          alert('Mohon cek kembali form anda');
        });

        
      
    },

  }
  
}
</script>