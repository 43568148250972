<template>
    
 
    <div class="section pp-scrollable bdz-section-directory-detail  slide-dark slide-personal-experience" :style="cssimgBgFacilitiesDetail">
            <div class="slide-container">
              
          <div class="slide-bg"></div>
          <div class="bdz-section-hover-blue"></div>

              <div class="container">
                <div class="row align-items-center mb-4 animate-element delay5 fadeInDown">
                  <div class="col-12">
                    <div class="bdz-directory-detail-back-button">
                        <a class="bdz-directory-detail-back-link" href="/#Facilities">Back to Home</a>
                    </div>
                    <h2 class="slide-title mb-0">Services & Facilities</h2>
                    <!-- <div class="experience-item-title">Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti maiores nisi ipsum minus provident cumque  </div> -->
                   
                  </div>
                
                </div>
                <div class="animate-element delay5 fadeInUp">
                  <div class="">
                    <div class="experience-list">
                      <div class="experience-item">
                        <div class="row bdz-career-item">
                          <div class="col-md-4 bdz-background-content">
                            <ul class="bdz-list-detail">
                              <h3 class="bdz-list-detail experience-item-title slide-title-sub">Facilities</h3>  
                              <hr />
                              <li v-for="facility in facilities" :key="facility.facilities_type_id" class="bdz-list-detail-li">
                                <RouterLink :to="{ name: 'facilities', params: { permalink: facility.facilities_type_permalink, type : 'facility' }}">
                                    <!-- <div class="bdz-facilities-list-item">
                                    <img :src="this.$assetImgSmall+'facilitiess/' + facility.facilities_icon" alt="" class="">
                                  </div> -->
                                  <div class="bdz-facilities-list-text">
                                    {{ facility.facilities_type_name }}
                                  </div>
                                  <div class="clearfix"></div> 
                                </RouterLink>
                              </li>
                              <br />
                              <br />
                              <h3 class="bdz-list-detail experience-item-title slide-title-sub">Services</h3>  
                              <hr />
                              <li v-for="service in services" :key="service.service_id" class="bdz-list-detail-li">
                                <RouterLink :to="{ name: 'facilities', params: { permalink: service.service_permalink, type : 'service' }}">
                                    <!-- <div class="bdz-service-list-item">
                                    <img :src="this.$assetImgSmall+'services/' + service.service_icon" alt="" class="">
                                  </div> -->
                                  <div class="bdz-service-list-text">
                                    {{ service.service_name }}
                                  </div>
                                  <div class="clearfix"></div> 
                                </RouterLink>
                              </li>

                              
                            </ul>
                            
                        </div>


                           <div class="col-md-8 bdz-background-content">
                            <div class="experience-item-title slide-title-sub">{{ title }}</div>
                            <img class="bdz-facilities-detail-img" :src="img" alt="">
                            <div class="experience-item-descr slide-descr">
                                <p v-html="desc"></p>
                            </div>
                            

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
             

                </div>
              </div>
            </div>
          </div>
</template>

<script>

export default{
  props: {
      imgBgFacilitiesDetail : String,
    },
    name : 'DetailFacilities',
    computed: {
      cssimgBgFacilitiesDetail() {
        return {
          "background-image": "url('"+ this.$assetImgSmall+'profile/' + this.imgBgFacilitiesDetail +"')",
          "background-position": "center",
        };
      },
      cssImgBgFacilities() {
        return  img => ({
          "background-image": "url('"+ this.$assetImgSmall+'facilities/' + img +"')",
          "background-position": "center",
        });
      },
     
    },
    methods: {
      async getServices() {
        await this.$axios
          .get(this.$api + '/services')
          .then((response) => {
            this.services = response.data.data;
            // if(response.data.data.length > 0){
            //   this.title = this.services[0].service_name;
            //   this.img = this.services[0].service_photo;
            //   this.desc = this.services[0].service_desc;
            // }
        });      
      },  

      async getFacilities() {
        await this.$axios
          .get(this.$api + '/facilities')
          .then((response) => {
            this.facilities = response.data.data;
            console.log(this.facilities);
            if(response.data.data.length > 0){
              this.title = this.facilities[0].facilities_type_name;
              this.img = this.$assetImgSmall + 'facilities/' + this.facilities[0].facilities_type_photo;
              this.desc = this.facilities[0].facilities_type_desc;
            }
        });
      },
      
      filteredServicesFacilties() {
        let tempservices = this.services;
        let tempFacilities = this.facilities;

        if(this.type == 'facility'){ // facilities

          tempFacilities = tempFacilities.filter((item) => {
            return item.facilities_type_permalink === this.permalink;
          })
          console.log(tempFacilities);
          if(tempFacilities.length>0){
            this.title = tempFacilities[0].facilities_type_name;
            this.img = this.$assetImgSmall + 'facilities/' + tempFacilities[0].facilities_type_photo;
            this.desc = tempFacilities[0].facilities_type_desc;
          }

        }else{ // services

          tempservices = tempservices.filter((item) => {
            console.log(item.service_permalink);
            console.log(this.permalink);
            return item.service_permalink === this.permalink;
          })

          if(tempservices.length>0){
            this.title = tempservices[0].service_name;
            this.img = this.$assetImgSmall + 'service/' + tempservices[0].service_photo;
            this.desc = tempservices[0].service_desc;
          }

        }
    
       
      }

     
    },
    data() {
      return {
            permalink: this.$route.params.permalink,
            type: 'facility',
            services : [],
            facilities : [],
            

            title:'',
            img: '',
            desc:'',
          }
    },
    mounted(){
      this.getServices();
      this.getFacilities();
    },
    watch: {
      $route (to){
          this.permalink = to.params.permalink;
          this.type = to.params.type;
          this.filteredServicesFacilties();
          // console.log(this.filteredServices);
          // console.log(to.params.permalink);
      }
    },
    
}

</script>