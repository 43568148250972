<template>
  <div class="section pp-scrollable slide-personal-awards" data-menuanchor="Waterpark">
    <div class="slide-container">
      <div class="slide-bg">
        <div class="slide-photo circle-golden slide-intro-circle1 transformLeft"></div>
        <div class="slide-photo circle-brown slide-intro-circle2 transformRight"></div>

      </div>
      <div class="container">
        <h2 class="slide-title">SERVICES & FACILITIES</h2>
        <!-- animate-element delay5 fadeInUp -->
        <div class="bdz-100" bis_skin_checked="1">

          <!-- <a class="bdz-20 bdz-service-facilities-item" v-for="facility in facilities" :key="facility.facilities_type_id" href="/facilities-detail" :style="cssImgBgFacilities(facility.facilities_type_photo)"> -->
          <a class="bdz-20 bdz-service-facilities-item" v-for="facility in facilities" :key="facility.facilities_type_id" :href="'/facilities/' + facility.facilities_type_permalink ">
            <div class="bdz-services-facilities-hover"></div>
            <div class="facilities-item-logo">
              <div class="bdz-facilities-item">
                <img :src="this.$assetImgSmall + 'facilities/' + facility.facilities_type_cover"  >
              </div>
            </div>
            <div class="bdz-service-facilities-text" bis_skin_checked="1">
            <!-- <img class="bdz-service-facilities-img" :alt="facility.facilities_type_name" :src="this.$assetImg +'facilities/' + facility.facilities_type_icon"> -->
                {{ facility.facilities_type_name }}</div>
          </a>
        </div>

        </div>
 </div>
 </div>
</template>


<script>
export default{
    props: {
      imgBgSlider : String,
    },
    name : 'MainFacilities',
    computed: {
      cssImgBgSlider() {
        return {
          "background-image": "url('"+ this.$assetImgSmall+'profile/' + this.imgBgSlider +"')",
        };
      },
      cssImgBgFacilities() {
        return  img => ({
          "background-image": "url('"+ this.$assetImgSmall+'facilities/' + img +"')",
        });
      }
    },
    methods: {
      async getFacilities() {
        await this.$axios
          .get(this.$api + '/facilities')
          .then((response) => {
            this.facilities = response.data.data;
        });
      },  
    },
    data() {
      return {
            facilities : [],
          }
    },
    mounted(){
      this.getFacilities();
    }
}
</script>