import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
// import VueMeta from 'vue-meta';



const app = createApp(App);

// app.use(VueMeta);
  


//constant

//local
// app.config.globalProperties.$http = "http://localhost:8080/";
// app.config.globalProperties.$api = "http://localhost:8080/api/"; 

//development   
app.config.globalProperties.$http = "https://admin-dev.pondokindahmall.co.id/";
app.config.globalProperties.$api = "https://admin-dev.pondokindahmall.co.id/api/";

//Live
// app.config.globalProperties.$http = "https://admin.pondokindahmall.co.id/";
// app.config.globalProperties.$api = "https://admin.pondokindahmall.co.id/api/";


app.config.globalProperties.$siteKeyCaptcha = "6LcCJPkpAAAAAI-f9Dx2dzk82tVuBrc4XQ4A95Q2";
app.config.globalProperties.$assetImg = app.config.globalProperties.$http+ "assets/img/";
app.config.globalProperties.$assetImgSmall = app.config.globalProperties.$http+ "assets/img_small/";
app.config.globalProperties.$axios = axios;

app.use(router);

let vm = app.mount("#app");
window.vm = vm;


