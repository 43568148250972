<template>
    
    <div class="section bdz-section-directory-detail slide-dark slide-dark-footer  pp-scrollable " data-menuanchor="Directorydetail" :style="cssImgBgDirectory">
        <div class="slide-container ">
          <div class="slide-bg"></div>
          <div class="bdz-section-directory-detail-hover"></div>

          <div class="container">
            <div class="row col-md-12">
              <h2 class="slide-title slide-title-personal-services slide-title-lg">MALL DIRECTORY</h2>
              <div class="bdz-directory-detail-back-button">
                <a href="/#Directory" class="bdz-directory-detail-back-link">Back to Tenant List</a>
                <!-- <RouterLink class="bdz-directory-detail-back-link" to="/#Directory">Back to Tenant List</RouterLink> -->
              </div>
            </div>
            <div class="row">
              
              <div class="col-md-8">
                
                <div class="bdz-directory-detail-wrapper animate-element delay5 fadeInLeft">
                  
                  <div class="bdz-directory-detail-container">
                    
                    <div class="row col-12 nopadding">
                      <div class="col-2 bdz-directory-detail-left nopadding">
                        <ul>
                        <li class="header-floor">Floor</li>
                        <li  @click.stop="toggleFloorActive(4)" 
                            v-bind:class="{ 
                              'bdz-directory-detail-header-active': (this.indexFloorSelected === 4 ? true : false),
                              'bdz-directory-detail-floor-4-hidden': (this.indexFloor4 ? true : false),
                             }">
                            R
                        </li>
                        <li  @click.stop="toggleFloorActive(3)" 
                            v-bind:class="{ 
                              'bdz-directory-detail-header-active': (this.indexFloorSelected === 3 ? true : false),
                              'bdz-directory-detail-floor-3-hidden': (this.indexFloor3  ? true : false),
                             }">
                            3
                        </li>
                        <li  @click.stop="toggleFloorActive(2)" 
                            v-bind:class="{ 
                              'bdz-directory-detail-header-active': (this.indexFloorSelected === 2 ? true : false),
                              'bdz-directory-detail-floor-2-hidden': (this.indexFloor2 ? true : false),

                             }">
                            2
                        </li>
                        <li  @click.stop="toggleFloorActive(1)" 
                            v-bind:class="{ 
                              'bdz-directory-detail-header-active': (this.indexFloorSelected === 1 ? true : false),
                              'bdz-directory-detail-floor-1-hidden': (this.indexFloor1? true : false),

                             }">
                            1
                        </li>
                        <li  @click.stop="toggleFloorActive(0)" 
                          v-bind:class="{ 
                              'bdz-directory-detail-header-active': (this.indexFloorSelected === 0 ? true : false),
                              'bdz-directory-detail-floor-0-hidden': (this.indexFloor0 ? true : false),

                           }">
                          GF
                        </li>
                        <li  @click.stop="toggleFloorActive(-1)" 
                            v-bind:class="{ 
                              'bdz-directory-detail-header-active': (this.indexFloorSelected === -1 ? true : false),
                              'bdz-directory-detail-floor--1-hidden': (this.indexFloorM1 ? true : false),

                             }">
                            LG
                        </li>
                        <li  @click.stop="toggleFloorActive(-2)" 
                            v-bind:class="{ 
                              'bdz-directory-detail-header-active': (this.indexFloorSelected === -2 ? true : false),
                              'bdz-directory-detail-floor--2-hidden': (this.indexFloorM2 ? true : false),

                             }">
                            B1
                        </li>
                        <li  @click.stop="toggleFloorActive(-3)" 
                            v-bind:class="{ 
                              'bdz-directory-detail-header-active': (this.indexFloorSelected === -3 ? true : false),
                              'bdz-directory-detail-floor--3-hidden': (this.indexFloorM3 ? true : false),

                             }">
                            B2
                        </li>
                        <li  @click.stop="toggleFloorActive(-4)" 
                            v-bind:class="{ 'bdz-directory-detail-header-active': (this.indexFloorSelected === -4 ? true : false),
                              'bdz-directory-detail-floor--4-hidden': (this.indexFloorM4? true : false),

                             }">
                            B3
                        </li>
                      </ul>
                      </div>
                      <div class="col-10 bdz-directory-detail-right nopadding">
                        <div class="col-12 row bdz-directory-detail-header nopadding">
                          <div class="bdz-directory-detail-header-item col-2" @click.stop="toggleUnitActive(1)" v-bind:class="{ 'bdz-directory-detail-header-active': (this.indexUnitSelected === 1 ? true : false)}">Street<br>Galery </div>
                          <div class="bdz-directory-detail-header-item col-2" @click.stop="toggleUnitActive(2)" v-bind:class="{ 'bdz-directory-detail-header-active': (this.indexUnitSelected === 2 ? true : false)}">PIM 1</div>
                          <div class="bdz-directory-detail-header-item col-2" @click.stop="toggleUnitActive(3)" v-bind:class="{ 'bdz-directory-detail-header-active': (this.indexUnitSelected === 3 ? true : false)}">PIM 2</div>
                          <div class="bdz-directory-detail-header-item col-2" @click.stop="toggleUnitActive(4)" v-bind:class="{ 'bdz-directory-detail-header-active': (this.indexUnitSelected === 4 ? true : false)}">PIM 3</div>
                          <div class="bdz-directory-detail-header-item col-2" @click.stop="toggleUnitActive(5)" v-bind:class="{ 'bdz-directory-detail-header-active': (this.indexUnitSelected === 5 ? true : false)}">North<br>Skywalk</div>
                          <div class="bdz-directory-detail-header-item col-2" @click.stop="toggleUnitActive(6)" v-bind:class="{ 'bdz-directory-detail-header-active': (this.indexUnitSelected === 6 ? true : false)}">South<br>Skywalk</div>
                        </div> 

                        <iframe :src="urlMap" ref="myiframe" @load="onIframeLoad" class="bdz-directory-detail-iframe" frameborder="0" allowtransparency="true"  name="myIframe"></iframe>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
              <div class="col-md-4">
                <div class="animate-element delay5 fadeInDown">
                  
                  <div class="bdz-container-directory-item">
                      <span class="bdz-container-directory-item-img">
                        <img :src="this.$assetImgSmall+'tenant/' + tenant.tenant_logo" alt="" class="bdz-directory-logo-item">
                        <img :src="this.$assetImgSmall+'tenant/' + tenant.tenant_photo" alt="" class="bdz-directory-photo-item">
                        <div class="clearfix"></div> 
                      </span>
                      
                      <div class="bdz-directory-item-tenant-name">
                         <b> {{ tenant.tenant_name }}</b>
                        <br>

                        <small>{{ tenant.category_name }}</small>
                        </div>      
                      
                      <div class="bdz-directory-item-tenant-desc">
                        <span :v-html="tenant.tenant_desc"></span>
                        {{ tenant.tenant_location }}
                        {{ tenant.tenant_floor }}
                        {{ tenant.tenant_area }}
                        {{ tenant.block_name }}
                        
                        <br>
                        <br>
                        <div class="bdz-directory-item-tenant-phone">
                          <a :href="'tel:' + tenant.tenant_phone">Call Now</a>
                        </div>
                      </div>
                    </div>


                </div>
              </div>
              
            </div>
           
          </div>
        </div>
      </div>
</template>


<script>

import EventBus from '../EventBus';

export default{

  props: {
    // id : String,
    imgBgDirectoryDetail : String,
  },
  name:'DetailDirectory',
  computed: {
    cssImgBgDirectory() {
      return {
        "background-image": "url('"+ this.$assetImgSmall+'profile/' + this.imgBgDirectoryDetail +"')",
        "background-position": "center",
      };
    },
  },
  mounted() {
    EventBus.on('call-child-method', this.changeTenant);

    

  },


  beforeUnmount() {
    EventBus.off('call-child-method', this.changeTenant);
  },
  methods: {
    async getTenantDetail() {
      await this.$axios
        .get(this.$api + 'tenantbypermalink/' + this.idx)
        .then((response) => {
          // console.log(this.$api + 'articledetail/' + this.idx);
          console.log(response.data.data);
          
          if (response.data.data.length != 0) {
            this.tenant = response.data.data;

            if(this.tenant.tenant_map =='sg') {this.resetFloorSG();}
            if(this.tenant.tenant_map =='p1') {this.resetFloorP1();}
            if(this.tenant.tenant_map =='p2') {this.resetFloorP2();}
            if(this.tenant.tenant_map =='p3') {this.resetFloorP3();}
            if(this.tenant.tenant_map =='skyn') {this.resetFloorSkyn();}
            if(this.tenant.tenant_map =='skys') {this.resetFloorSkys();}

            var unit = this.tenant.tenant_map ==='sg' ? 1 : (this.tenant.tenant_map === 'p1' ? 2 : (this.tenant.tenant_map === 'p2' ? 3 : (this.tenant.tenant_map === 'p3' ? 4 : (this.tenant.tenant_map === 'skyn' ? 5 : 6))));
              
            this.mapActive = this.tenant.tenant_map;
            this.toggleFloorActive(parseInt(this.tenant.tenant_floor));
            this.indexUnitSelected = unit;

            setTimeout(() => {
              this.$refs.myiframe.contentWindow.setTenantActive(this.tenant.tenant_block);
              console.log(this.tenant.tenant_block);
            }, 2000);
          }
         
        });
    },  

    onIframeLoad() {
      this.iframeWindow = this.$refs.myiframe.contentWindow;
      // window.addEventListener('message', this.handleMessage);
    },

    async getTenantDetailByLoc(loc) {
      await this.$axios
        .get(this.$api + 'tenantbyloc/' + loc)
        .then((response) => {
          if(response.data.data)
            this.tenant = response.data.data;
      });
    },  

      toggleFloorActive(index) {
        this.indexFloorSelected = index;
        var map = this.mapActive + '-' + index + '.html'
        this.urlMap = '../map/'+ map;
      },


      toggleUnitActive(index) {
        if (index == 1) {
          this.urlMap = '../map/sg-0.html';
          this.mapActive = 'sg'
          this.resetFloorSG();
          this.indexFloorSelected=0;
        }
        if (index == 2) {
          this.urlMap = '../map/p1-0.html';
          this.mapActive = 'p1'
          this.resetFloorP1();
          this.indexFloorSelected=0;
        }
        if (index == 3) {
          this.urlMap = '../map/p2-0.html';
          this.mapActive = 'p2'
          this.resetFloorP2();
          this.indexFloorSelected=0;
        }
        if (index == 4) {
          this.urlMap = '../map/p3-0.html';
          this.mapActive = 'p3'
          this.resetFloorP3();
          this.indexFloorSelected=0;
        }
        if (index == 5) {
          this.urlMap = '../map/skyn-1.html';
          this.mapActive = 'skyn'
          this.resetFloorSkyn();
          this.indexFloorSelected=1;
        }
        if (index == 6) {
          this.urlMap = '../map/skys-1.html';
          this.mapActive = 'skys'
          this.resetFloorSkys ();
          this.indexFloorSelected=1;
        }
        this.indexUnitSelected = index;
      },
      resetFloor(){
        this.indexFloor4=true;
          this.indexFloor3=true;
          this.indexFloor2=true;
          this.indexFloor1=true;
          this.indexFloor0=true;
          this.indexFloorM1=true;
          this.indexFloorM2=true;
          this.indexFloorM3=true;
          this.indexFloorM4=true;
      },
      resetFloorSG(){
        this.resetFloor();
        this.indexFloor2=false;
        this.indexFloor1=false;
        this.indexFloor0=false;
      },
      resetFloorP1(){
          this.resetFloor();
          this.indexFloor2=false;
          this.indexFloor1=false;
          this.indexFloor0=false;
      },
      resetFloorP2(){
          this.resetFloor();
          this.indexFloor4=false;
          this.indexFloor3=false;
          this.indexFloor2=false;
          this.indexFloor1=false;
          this.indexFloor0=false;
          this.indexFloorM1=false;
          this.indexFloorM2=false;
          this.indexFloorM3=false;
      },
      resetFloorP3(){
          this.resetFloor();
          this.indexFloor3=false;
          this.indexFloor2=false;
          this.indexFloor1=false;
          this.indexFloor0=false;
          this.indexFloorM1=false;
          this.indexFloorM2=false;
          this.indexFloorM3=false;
      },
      resetFloorSkyn(){
          this.resetFloor();
          this.indexFloor2=false;
          this.indexFloor1=false;
      },
      resetFloorSkys(){ 
        this.resetFloor();
          this.indexFloor2=false;
          this.indexFloor1=false;
      },
      changeTenant(loc){
          this.getTenantDetailByLoc(loc);
      }
   
  },



  data() {
    return {
      tenant : {},
      selectedTenant : '0',
      idx : this.$route.params.id,
      // isFloorActive:false,
      indexFloorSelected:0,
      indexUnitSelected:2,
      urlMap : "../map/p1-0.html",
      mapActive : "p1",

      indexFloor4 : true,
      indexFloor3 : true,
      indexFloor2 : false,
      indexFloor1 : false,
      indexFloor0 : false,
      indexFloorM1 : true,
      indexFloorM2 : true,
      indexFloorM3 : true,
      indexFloorM4 : true,
      
    }   
  },
  beforeMount(){
    this.getTenantDetail();
  },
  // setup(props){
  //   props.idx = this.$route.params.id;
  // }
 
}
</script>
