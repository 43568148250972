<template>
    
 
    <div class="section pp-scrollable bdz-section-directory-detail  slide-dark slide-personal-experience" :style="cssimgBgFacilitiesDetail">
            <div class="slide-container">
              
          <div class="slide-bg"></div>
          <div class="bdz-section-hover-red"></div>

              <div class="container">
                <div class="row align-items-center mb-4 animate-element delay5 fadeInDown">
                  <div class="col-12">
                    <div class="bdz-directory-detail-back-button">
                        <a class="bdz-directory-detail-back-link" href="/#slider">Back to Home</a>
                    </div>
                    <h2 class="slide-title mb-0">{{ this.title }}</h2>
                    <!-- <div class="experience-item-title">Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti maiores nisi ipsum minus provident cumque  </div> -->
                   
                  </div>
                
                </div>
                <div class="animate-element delay5 fadeInUp">
                  <div class="">

                    <div  class=" bdz-whatsnew-content animate-element delay5 fadeInUp">

                        <div v-for="item in articles" :key="item.shopnwin_id" class="col-lg-4 col-4 p-2 m-0 bdz-listview-item">
                            <a class="wnew-item" :href="'/'+ this.type +'/' + item.news_permalink">
                            <div class="wnew-item-logo">
                                <div class="bdz-award-item" v-for="images in item.news_image" :key="images.img_id">
                                    <img :src="this.$assetImgSmall + 'news/' + images.img_name" > 
                                    <div class="bdz-wnew-desc">
                                      <div class="bdz-wnew-desc-text">
                                        {{ item.news_title }}
                                      </div> 
                                      <div class="clearfix"></div>
                                      <div class="bdz-wnew-desc-text-readmore">
                                        Read more
                                      </div> 
                                  </div>                       
                                </div>
                            </div>
                            </a>
                        </div>
                    </div>
                    <div class="clearfix"></div> 
                    <br>
                    <div v-if="this.isProcessLoadMore === false" class="col-sm-12 col-12 bdz-more-article-wrapper text-center">
                            <div class="bdz-more-article-container">
                              <a class="btn btn-success btn-sm bdz-more-article-btn" @click="loadMore()">Load More</a>
                            </div>
                          </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
</template>

<script>

export default{
  props: {
      imgBgFacilitiesDetail : String,
    },
    name : 'DetailListView',
    computed: {
      cssimgBgFacilitiesDetail() {
        return {
          "background-image": "url('"+ this.$assetImgSmall+'profile/' + this.imgBgFacilitiesDetail +"')",
          "background-position": "center",
        };
      },
      cssImgBgFacilities() {
        return  img => ({
          "background-image": "url('"+ this.$assetImgSmall+'facilities/' + img +"')",
        });
      },
     
    },
    methods: {
        
        async getFeatured() {
            this.isProcessLoadMore = true;
            await this.$axios
            .get(this.$api + '/featured/'+ this.limit + '/'+ this.page)
            .then((response) => {
                this.articles.push(...response.data.data);

                // this.articles = this.articles + response.data.data;
                this.page = this.page + 1;
                if(response.data.data.length > 0)
                    this.isProcessLoadMore = false;
            });
        },  
        
        async getWhatsnew() {
            this.isProcessLoadMore = true;
            await this.$axios
                .get(this.$api + '/whatsnew/'+ this.limit + '/'+ this.page)
                .then((response) => {
                    this.articles.push(...response.data.data);
                this.page = this.page + 1;
                
                if(response.data.data.length > 0)
                    this.isProcessLoadMore = false;
            });
        },  
        async getCityhall() {
            this.isProcessLoadMore = true;
            await this.$axios
                .get(this.$api + '/cityhall/'+ this.limit + '/'+ this.page)
                .then((response) => {
                // console.log(response.data.data);
                this.articles.push(...response.data.data);
                this.page = this.page + 1;
                
                if(response.data.data.length > 0)
                    this.isProcessLoadMore = false;
            });
        },  
        async getEvents() {
            this.isProcessLoadMore = true;
            await this.$axios
            .get(this.$api + '/events/'+ this.limit + '/'+ this.page)
            .then((response) => {
                // console.log(response.data.data);
                this.articles.push(...response.data.data);
                this.page = this.page + 1;
                
                if(response.data.data.length > 0)
                    this.isProcessLoadMore = false;
            });
        },  
        loadMore(){
            
            if (this.type == 'featured') {
                this.getFeatured();
                this.title = "Featured News";
            }else if(this.type == 'cityhall'){
                this.getCityhall();
                this.title = "PIM City Hall News";
            }else if(this.type == 'event'){
                this.getEvents();
                this.title = "PIM Events";
            }else{
                this.type = "whatsnew";
                this.title = "What's New on PIM";
                this.getWhatsnew();
            }
        }
     
    },
    data() {
      return {
            
            articles : [],
            page : 1,
            limit : 6,
            
            type: this.$route.params.type,
            isProcessLoadMore : false,

            title:'',
          }
    },
    mounted(){
       this.loadMore();
    },
}

</script>