<template>
      <footer id="footer" class="footer">
            <ul class="social social-fixed">
            <li>
                <a :href="'https://twitter.com/'+ this.x"><i class="lni lni-twitter-filled"></i></a>
            </li>
            <li>
                <a :href="'https://facebook.com/'+this.facebook"><i class="lni lni-facebook-original"></i></a>
            </li>
            <li>
                <a :href="'https://instagram.com/'+this.instagram"><i class="lni lni-instagram-original"></i></a>
            </li>
            </ul>

            <div class="copyright copyright-fixed d-none d-md-block">
            &copy; Pondok Indah Mall 2024<br />
            All Rights Reserved
            </div>
        </footer>
</template> 

<script>
export default{
    name : 'MainFooter',
    props: {
      x: String,
      facebook: String,
      instagram : String,
    },
}
</script>