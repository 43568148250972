<template>
    
 
    <div class="section pp-scrollable bdz-section-directory-detail  slide-dark slide-personal-experience" :style="cssimgBg">
            <div class="slide-container">
              
          <div class="slide-bg"></div>
          <div class="bdz-section-directory-detail-hover"></div>

              <div class="container">
                <div class="row align-items-center mb-4 animate-element delay5 fadeInDown">
                  <div class="col-12">
                    <div class="bdz-directory-detail-back-button">
                        <a class="bdz-directory-detail-back-link" href="/#Contact">Back to Home</a>
                    </div>
                    <h2 class="slide-title mb-0">career
                        
                    </h2>
                   
                  </div>
                
                </div>
                <div class="animate-element delay5 fadeInUp">
                  <div class="carousel-experience a-carousel-experience owl-carousel owl-theme">
                    <div class="experience-list bdz-background-content">
                      <div v-for="item in career" :key="item.career_id" class="experience-item">
                        <div class="row bdz-career-item">
                          <div class="col-md-4">
                            <div class="experience-item-title slide-title-sub">{{ item.career_name }}</div>
                          </div>
                          <div class="col-md-8 ">
                            <div class="experience-item-descr slide-descr" v-html="item.career_short_desc"></div>
                            <div class="bdz-career-item-detail" v-html="item.career_desc"></div>
                        </div>
                        </div>
                      </div>
                      
                    </div>
                   
                    
                  </div>
                  <div class="row">
                    <div class="col-12 text-right">
                        <div class="a-carousel-nav carousel-nav"></div>
                    </div>
                  </div>
                  <div class="slide-btn bdz-career-additional-info bdz-background-content">
                    <!-- <a href="#" class="btn btn-success"><i class="lni lni-download mr-3"></i>download my cv</a> -->
                    apabila anda tertarik untuk bergabung dengan kami, silahkan email ke <b>hrd@pondokindahmall.co.id</b>, dengan subject career
                  </div>
                </div>
              </div>
            </div>
          </div>
</template>


<script>


export default{
  props: {
    imgBgCareer : String,

    profileName : String,
    profileLogo : String,

    },
    name : 'MainCareer',
    computed: {
      cssimgBg() {
        return {
          "background-image": "url('"+ this.$assetImgSmall+'profile/' + this.imgBgCareer +"')",
          "background-position": "center",
        };
      },
      cssImgBgFacilities() {
        return  img => ({
          "background-image": "url('"+ this.$assetImgSmall+'facilities/' + img +"')",
          "background-position": "center",
        });
      },
     
    },
    methods: {
      async getCareer() {
        await this.$axios
          .get(this.$api + '/career')
          .then((response) => {
            this.career = response.data.data;



             


              
        });
      },  
     
    },
    data() {
      return {
            permalink: '',
            career : [],

            title:'',
            img: '',
            desc:'',
          }
    },
    mounted(){
      this.getCareer();
    },
 
}

</script>