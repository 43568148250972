<template>
  <div class="section pp-scrollable slide-dark slide-personal-testimonials">
            <div class="slide-container">
              <div class="slide-bg">
                <div class="slide-photo circle-golden slide-testimonials-circle3 animate-element delay5 fadeInUp"></div>
                <div class="bdz-wp-rides-detail-bg-bottom animate-element delay1  fadeInDown">
            <img src="../assets/img/bg-wp-det-top.png" alt="">
        </div> 
              </div>
              <div class="container">
                <div class="bdz-rides-detail-back">
                  <a href="/#Rides" class="bdz-rides-detail-back-button">Back to home</a>
                </div>
                <div class="clearfix"></div>
                <div class="row">
                  <div class="col-md-5 col-sm-10 mb-4">
                    <div class="video-link">

                      <div class="slide-photo circle-green slide-testimonials-circle1 transformLeft"></div>
                      <div class="slide-photo circle-brown slide-testimonials-circle2 transformRight"></div>
                      <span v-for="images in wp.wp_content_image" :key="images.img_id">
                            <a :href="this.$assetImgSmall+'wp_content/' + images.img_name" data-fancybox class="inside animate-element delay6 fadeIn" :style="cssImgBgWP(images.img_name)">
                              <img :src="this.$assetImgSmall+'wp_content/' + images.img_name" alt="">
                            </a>
                        </span>
                        
                    </div>
                    <!-- <div class="video-link-descr slide-descr">*Sanfasio Agency’s Stories</div> -->
                  </div>
                  <!-- <div class="col-md-1 col-xl-2 d-none d-lg-block"></div> -->
                  <div class="col-xl-7 col-lg-6 col-md-7 bdz-background-content">
                    <div class="carousel-testimonial a-carousel-testimonial owl-carousel owl-theme animate-element delay5 fadeInDown">
                      <div  class="testimonials-item">
                        <div class="testimonials-item-text slide-title-info">{{wp.wp_content_title}}</div>
                        <div class="testimonials-item-author">Pondok Indah Waterpark</div>
                        <br>
                        <div class="testimonials-desc">
                            <p>{{ wp.wp_content_excert }}</p>
                            <p v-html="wp.wp_content_desc"></p>
                            
                        </div>
                        
                      </div>
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div class="bdz-wp-menu-wrapper  animate-element delay3 fadeInDown">
              <div class="bdz-wp-menu-container">
                <img src="../assets/img/logo-wp.png" alt="" class="bdz-wp-menu-logo">
                <div class="clearfix"></div>
                <!-- <ul>
                  <li>Rides</li>
                  <li>Info</li>
                  <li>Reservation</li>
                  <li>Buy Ticket</li>
                  <li>News</li>
                </ul> -->
              </div>
            </div>

          </div>



</template>




<script>
import { setMetaTags } from '../utils/Meta';

export default{
  props: {
    // id : String,
    imgBgEventDetail : String,

    profileName : String,
    profileLogo : String,
  },
  name:'DetailRides',
  computed: {
    cssImgBgWP() {
      return img => ({
          "background-image": "url('"+ this.$assetImgSmall+'wp_content/' + img +"')",
        });
    }
  },
  methods: {
    async getWPDetail() {
      await this.$axios
        .get(this.$api + 'wpdetail/' + this.idx)
        .then((response) => {
          // console.log(this.$api + 'articledetail/' + this.idx);
          console.log(response.data.data);
          this.wp = response.data.data;


             // set metadata website
             setMetaTags({
              title: this.wp.wp_content_title + ' | Pondok Indah Mall',
              metaTags: [
                { name: 'description',content: this.wp.wp_content_excert},
                { name: 'robots',content: 'index,follow'},
                { property: 'og:title',  content: this.wp.wp_content_title + ' | Pondok Indah Mall'},
                { property: 'og:description',  content: this.wp.wp_content_excert},
                { property: 'og:site_name',  content: 'Pondok Indah Mall'},
                { property: 'og:type',  content: 'article'},
                { property: 'og:image:type',  content: 'image/webp'},
                { property: 'og:image',  content: this.$assetImgSmall + 'rides/' + this.wp.wp_content_image[0].img_name},
                { property: 'og:url',  content: 'https://pondokindahmall.co.id/rides/' + this.idx},
                { property: 'twitter:card',  content: 'summary_large_image'},
                { property: 'twitter:site',  content: '@pondokindahmall'},
                { property: 'twitter:site:id',  content: '@pondokindahmall'},
                { property: 'twitter:creator',  content: '@pondokindahmall'},
                { property: 'twitter:title',  content: this.wp.wp_content_title + ' | Pondok Indah Mall'},
                { property: 'twitter:url',  content: 'https://pondokindahmall.co.id/rides/'+this.idx},
                { property: 'twitter:description',  content: this.wp.wp_content_excert},
                { property: 'twitter:image:src',  content: this.$assetImgSmall + 'rides/' + this.wp.wp_content_image[0].img_name},

              ]
            })

            
      });
    },  
  },
  data() {
    return {
          wp : {},
          idx : this.$route.params.id,
        }
  },
  beforeMount(){
    this.getWPDetail();
  },
  // setup(props){
  //   props.idx = this.$route.params.id;
  // }
}
</script>